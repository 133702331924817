import {
  Box,
  Container,
  Divider,
  ImageList,
  ImageListItem,
  Link,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getOfferProposal, getUsername } from "../../services/offerService";
import { OfferProposalFireStoreI } from "../../Client/Dashboard/OfferView/OfferProposal";
import BorderColorIcon from "@mui/icons-material/BorderColor";

export const OfferProposalViewer = () => {
  const [offerProposal, setOfferProposal] = useState<
    OfferProposalFireStoreI | undefined
  >();

  const [userName, setUsername] = useState<string>();

  const { state } = useLocation();
  const fetchOfferProposal = async () => {
    const data = await getOfferProposal(state.uid);
    if (data) {
      setOfferProposal({
        id: data.id,
        offerProposalData: {
          city: data.offerProposalData.city,
          postalcode: data.offerProposalData.postalcode,
          street: data.offerProposalData.street,
          street_number: data.offerProposalData.street_number,
          permits: data.offerProposalData.permits,
          lantern: data.offerProposalData.lantern,
          backyard: data.offerProposalData.backyard,
          bollard: data.offerProposalData.bollard,
          watercap: data.offerProposalData.watercap,
          frontview: data.offerProposalData.frontview,
          sideview: data.offerProposalData.sideview,
          sidewalk_length: data.offerProposalData.sidewalk_length,
          sidewalk_width: data.offerProposalData.sidewalk_width,
          sidewalkpavement: data.offerProposalData.sidewalkpavement,
        },
        status: data.status,
        timeStamp: data.timeStamp,
      });
    }
  };

  const fetchUsername = async () => {
    const data = await getUsername(state.uid);
    if (data) {
      setUsername(data.fullName);
    }
  };

  useEffect(() => {
    fetchOfferProposal();
    fetchUsername();
  }, [state.uid]);
  return (
    <Container
      maxWidth="md"
      sx={{
        boxShadow: 4,
        my: 5,
        p: 5,
        backgroundColor: "white",
        borderRadius: 2,
      }}
    >
      {offerProposal && (
        <>
          <Typography variant="h5" color="primary" sx={{ mb: 5 }}>
            Bauvorhaben:{" "}
            {offerProposal.offerProposalData.street +
              " " +
              offerProposal.offerProposalData.street_number}
          </Typography>

          <Box>
            <Typography variant="h5" sx={{ my: 1 }}>
              <BorderColorIcon color="primary" /> Übersicht
            </Typography>
            <Divider component="div" variant="middle" />

            <Box
              display="display"
              flexDirection="column"
              justifyContent="center"
              sx={{ p: 1 }}
            >
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Frau/Herr {userName && userName}
              </Typography>
            </Box>
          </Box>

          <Box>
            <Typography variant="h5" sx={{ my: 1 }}>
              <BorderColorIcon color="primary" /> Bauspezifische Informationen
            </Typography>
            <Divider component="div" variant="middle" />

            <Box
              display="display"
              flexDirection="column"
              justifyContent="center"
              sx={{ p: 1 }}
            >
              <Typography variant="body1">
                <b>Gehwegfläche:</b>{" "}
                {(
                  offerProposal.offerProposalData.sidewalk_length *
                  offerProposal.offerProposalData.sidewalk_width
                ).toFixed(2)}{" "}
                m²
              </Typography>
              <Typography variant="body1">
                <b>Vorgarten:</b>{" "}
                {offerProposal.offerProposalData.backyard ? "Ja" : "Nein"}
              </Typography>

              <Typography variant="body1">
                <b>Hindernisse:</b>{" "}
                {offerProposal.offerProposalData.lantern ? "Laterne" : ""},{" "}
                {offerProposal.offerProposalData.watercap ? "Wasserkappe" : ""},{" "}
                {offerProposal.offerProposalData.bollard ? "Pöller" : ""}
              </Typography>

              <Typography variant="body1">
                <b>Gehweglänge:</b>{" "}
                {offerProposal.offerProposalData.sidewalk_length.toFixed(2)} m
              </Typography>
              <Typography variant="body1">
                <b>Gehwegbreite:</b>{" "}
                {offerProposal.offerProposalData.sidewalk_width.toFixed(2)} m
              </Typography>
              <Typography variant="body1">
                <b>Bodenbelag:</b>{" "}
                {offerProposal.offerProposalData.sidewalkpavement}
              </Typography>
            </Box>
          </Box>

          <Box>
            <Typography variant="h5" sx={{ my: 1 }}>
              <BorderColorIcon color="primary" /> Downloads
            </Typography>
            <Divider component="div" variant="middle" />
            <List>
              <ListItem>
                <Link
                  href={offerProposal.offerProposalData.permits}
                  target="_blank"
                >
                  Genehmigung
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  href={offerProposal.offerProposalData.frontview}
                  target="_blank"
                >
                  Seitenansicht
                </Link>
              </ListItem>

              <ListItem>
                <Link
                  href={offerProposal.offerProposalData.sideview}
                  target="_blank"
                >
                  Frontansicht
                </Link>
              </ListItem>
            </List>
          </Box>

          <Box>
            <Typography variant="h5" sx={{ my: 1 }}>
              <BorderColorIcon color="primary" /> Gehwegbilder
            </Typography>
            <Divider component="div" variant="middle" />

            <ImageList>
              <ImageListItem>
                <img
                  alt="sideview"
                  src={offerProposal.offerProposalData.sideview}
                />
              </ImageListItem>

              <ImageListItem>
                <img
                  alt="frontview"
                  src={offerProposal.offerProposalData.frontview}
                />
              </ImageListItem>
            </ImageList>
          </Box>
        </>
      )}
    </Container>
  );
};
