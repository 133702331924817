import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { Position } from "../../Position/components/CsvImport";

interface PositionTableProps {
  positionState: {
    positions: Position[];
    setPositions: React.Dispatch<React.SetStateAction<Position[]>>;
  };
  editState: {
    edit: boolean;
    setEdit: React.Dispatch<React.SetStateAction<boolean>>;
  };
}
export const PositionTable = (props: PositionTableProps) => {
  const { edit, setEdit } = props.editState;
  const { positions, setPositions } = props.positionState;

  const handleMengeChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    if (positions) {
      const newArray = positions.map((position, i) => {
        if (index === i) {
          return { ...position, [e.target.name]: e.target.value };
        } else {
          return position;
        }
      });
      setPositions(newArray);
    }
  };

  return edit ? (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Position</TableCell>

            <TableCell align="left">Text</TableCell>
            <TableCell align="left">Menge</TableCell>
            <TableCell align="left">Einheit</TableCell>
            <TableCell align="left">Preis/Einheit in €</TableCell>
            <TableCell align="left">Gesamtpreis in €</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {positions &&
            positions.map((row, index) => {
              return (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.nr}
                  </TableCell>
                  <TableCell align="left">{row.text}</TableCell>
                  <TableCell align="left">
                    <TextField
                      size="small"
                      type="number"
                      name="menge"
                      value={row.menge}
                      onChange={(e) => {
                        handleMengeChange(e, index);
                      }}
                      onFocus={(event) => {
                        event.target.select();
                      }}
                    ></TextField>
                  </TableCell>
                  <TableCell align="left">{row.einheit}</TableCell>
                  <TableCell align="left">
                    {row.price.toFixed(2) + "€"}
                  </TableCell>
                  <TableCell align="left">
                    {(row.price * row.menge).toFixed(2) + "€"}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Position</TableCell>

            <TableCell align="left">Text</TableCell>
            <TableCell align="left">Menge</TableCell>
            <TableCell align="left">Einheit</TableCell>
            <TableCell align="left">Preis/Einheit in €</TableCell>
            <TableCell align="left">Gesamtpreis in €</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {positions &&
            positions.map((row, index) => (
              <TableRow
                key={index + "edit"}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell key={index + "nr"} component="th" scope="row">
                  {row.nr}
                </TableCell>
                <TableCell align="left" key={index + "text"}>
                  {row.text}
                </TableCell>
                <TableCell align="left" key={index + "menge"}>
                  {row.menge}
                </TableCell>
                <TableCell align="left">{row.einheit}</TableCell>
                <TableCell align="left" key={index + "preis"}>
                  {row.price.toFixed(2) + "€"}
                </TableCell>
                <TableCell align="left">
                  {(row.price * row.menge).toFixed(2) + "€"}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
