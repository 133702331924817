import { collection, setDoc, doc, getDoc } from "firebase/firestore";
import { auth, db, app } from "../firebaseConfig";
import { Position } from "../Admin/Position/components/CsvImport";
export const savePositions = async (positions: Position[]) => {
  const user = auth.currentUser;
  if (user) {
    if (user.email) {
      const docRef = await setDoc(doc(db, "positions", user.uid), {
        positions: positions,
      });
    }
  }
};

export const getPositions = async () => {
  const user = auth.currentUser;
  if (user) {
    if (user.email) {
      const docSnap = await getDoc(doc(db, "positions", user.uid));
      if (docSnap.exists()) {
        return docSnap.data();
      }
    }
  }
};
