import { useEffect, useState } from "react";
import { getPositions } from "../../../services/positionService";
import { Position } from "../../Position/components/CsvImport";

export const usePositions = () => {
  const [positions, setPositions] = useState<Position[]>();

  useEffect(() => {
    const fetch = async () => {
      const res = await getPositions();

      if (res) {
        const positions: Position[] = await res.positions;
        setPositions(positions);
      }
    };
    fetch();
  }, []);
  return { positions, setPositions };
};
