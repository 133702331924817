import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import InputField from "../../components/InputField";
import { DialogProps } from "../../../FormikModels/sidewalkInitialFormValues";
import { sidewalkFormModel } from "../../../FormikModels/sidewalkFormModel";
import { sL } from "./sidewalkLanguage";

export const SideWalkLocationDialog = (props: DialogProps) => {
  const {
    formField: { street, street_number, city, postalcode },
  } = sidewalkFormModel;
  return (
    <Box sx={{ py: 2 }}>
      <Box>
        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item xs={12}>
            <Typography component="h1" variant="h6">
              {sL.SIDEWALK_LOCATION}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              name={street.name}
              label={street.label}
              fullWidth
              variant="standard"
            />
          </Grid>

          <Grid item xs={6} md={2}>
            <InputField
              name={street_number.name}
              label={street_number.label}
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid item xs={6} md={4}></Grid>

          <Grid item xs={12} md={6}>
            <InputField
              name={city.name}
              label={city.label}
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <InputField
              name={postalcode.name}
              label={postalcode.label}
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid item xs={6} md={4}></Grid>
        </Grid>
      </Box>
    </Box>
  );
};
