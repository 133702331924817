import { Box, Typography } from "@mui/material";
import React from "react";
import { Dialog } from "../../components/Dialog";
import { FileUpload } from "../../components/FileUpload";
import { sL } from "./sidewalkLanguage";
import { sidewalkFormModel } from "../../../FormikModels/sidewalkFormModel";
export const PermitDialog = () => {
  const {
    formField: { permits },
  } = sidewalkFormModel;
  return (
    <Dialog>
      <Typography
        sx={{
          fontSize: {
            lg: 30,
            md: 18,
            sm: 18,
            xs: 18,
          },
        }}
        variant="h6"
        color="text.secondary"
      >
        {sL.SIDEWALK_PERMIT}
      </Typography>

      <Box
        sx={{ mt: 2 }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <FileUpload
          type="pdf"
          name={permits.name}
          buttonText="PDF hochladen"
          fileName="permit"
        />
      </Box>
    </Dialog>
  );
};
