import { doc, getDoc, setDoc } from "firebase/firestore";
import { auth, db } from "../firebaseConfig";
import { WorkflowI } from "../Admin/Workflow/views/WorkFlow";

export const saveWorkflow = async (workflow: WorkflowI) => {
  const user = auth.currentUser;
  if (user) {
    if (user.email) {
      const docRef = await setDoc(doc(db, "workflows", user.uid), {
        ...workflow,
      });
    }
  }
};

export const getWorkflow = async () => {
  const user = auth.currentUser;
  if (user) {
    if (user.email) {
      const docSnap = await getDoc(doc(db, "workflows", user.uid));
      if (docSnap.exists()) {
        return docSnap.data();
      }
    }
  }
};
