import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { Dialog } from "../../components/Dialog";
import { sL } from "./sidewalkLanguage";

interface SuccessDialogProps {
  downloadAble: boolean;
}
export const SuccessDialog = (props: SuccessDialogProps) => {
  return (
    <Dialog>
      <Typography
        sx={{
          fontSize: {
            lg: 30,
            md: 20,
            sm: 20,
            xs: 20,
          },
        }}
        variant="h6"
        color="primary"
      >
        {sL.SIDEWALK_SUCCESS}
      </Typography>

      <Box
        sx={{ mt: 2 }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Button component={Link} to="/angebote" variant="contained">
          Zur Angebotsübersicht
        </Button>
      </Box>
    </Dialog>
  );
};
