import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { registrationInitialValues } from "../../FormikModels/registrationInitialValues";
import { registrationFormValidationSchema } from "../../FormikModels/registrationFormValidationSchema";
import { Form, Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { registrationFormModel } from "../../FormikModels/registrationFormModel";
import InputField from "../../Client/components/InputField";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebaseConfig";
import { User, UserContext, userContextProps } from "../../App";
import { saveUser } from "../../services/authService";

export interface ErrorI {
  error: boolean;
  msg: string;
}
export const RegistrationView = () => {
  const {
    formField: { email, password, fullName },
  } = registrationFormModel;

  const { setUser } = useContext<userContextProps>(UserContext);
  const navigate = useNavigate();
  const [error, setError] = useState<ErrorI>({ error: false, msg: "" });
  return (
    <Container maxWidth="sm" sx={{ mt: 20 }}>
      <Box>
        <Box sx={{ mt: 5, backgroundColor: "white", p: 2, borderRadius: 1 }}>
          <Stack direction="row" alignItems="center" gap={1}>
            <ContentPasteIcon color="primary" />
            <Typography component="h5" variant="h5">
              Jetzt Registrieren und Angebot erstellen lassen.
            </Typography>
          </Stack>
          <Formik
            initialValues={registrationInitialValues}
            validationSchema={registrationFormValidationSchema[0]}
            onSubmit={(values) => {
              createUserWithEmailAndPassword(
                auth,
                values.email,
                values.password
              )
                .then(async (userCredential) => {
                  saveUser(values.fullName);

                  const jwt = await userCredential.user.getIdToken();
                  const userContextObject = {
                    email: userCredential.user.email,
                    jwt: jwt,
                  } as User;
                  setUser(userContextObject);
                  navigate("/angebote");
                })
                .catch((error) => {
                  if (error.code === "auth/email-already-in-use") {
                    setError({ error: true, msg: "Email existiert bereits" });
                  }
                });
            }}
          >
            {() => (
              <Form>
                <Box sx={{ mt: 3 }}>
                  <InputField fullWidth name={email.name} label={email.label} />
                  <InputField
                    fullWidth
                    name={password.name}
                    label={password.label}
                  />

                  <InputField
                    fullWidth
                    name={fullName.name}
                    label={fullName.label}
                  />
                  <Button component={Link} to="/">
                    AGB
                  </Button>
                  <Button component={Link} to="/">
                    Datenschutz
                  </Button>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Los gehts!
                  </Button>
                  {error.error && <Alert severity="error">{error.msg}</Alert>}
                </Box>
              </Form>
            )}
          </Formik>

          <Grid container>
            <Grid item xs>
              <Button component={Link} to="/">
                Zum Login
              </Button>
            </Grid>

            <Grid container></Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};
