import { Box, Typography } from "@mui/material";
import React from "react";
import { Dialog } from "../../components/Dialog";
import { SliderModule } from "../../components/SliderModule";
import { sidewalkFormModel } from "../../../FormikModels/sidewalkFormModel";

export const SidewalkMetrics = () => {
  const {
    formField: { sidewalk_width, sidewalk_length },
  } = sidewalkFormModel;
  return (
    <Dialog>
      <Typography
        sx={{
          fontSize: {
            lg: 30,
            md: 20,
            sm: 20,
            xs: 20,
          },
        }}
        variant="h6"
      >
        Wir benötigen Ihre Gehwegmaße für ein optimales Angebot.
      </Typography>
      <Box sx={{ mt: 2, width: 300 }}>
        <Box sx={{ mt: 2, width: 300 }}>
          <Typography gutterBottom>Gehwegbreite in m</Typography>
          <SliderModule
            defaultValue={1}
            step={0.1}
            min={0.8}
            max={2.0}
            marks={[
              {
                value: 0.8,
                label: "0.80 m",
              },
              {
                value: 1.6,
                label: "1.60 m",
              },

              {
                value: 2.0,
                label: "2,00 m",
              },
            ]}
            name={sidewalk_width.name}
            meter={false}
          />
        </Box>

        <Box sx={{ mt: 2, width: 300 }}>
          <Typography gutterBottom>Gehwegabsenkungslänge in Metern</Typography>
          <SliderModule
            meter={true}
            marks={[
              {
                value: 5.0,
                label: "5 m",
              },
              {
                value: 7.0,
                label: "7 m",
              },

              {
                value: 14.0,
                label: "14 m",
              },
            ]}
            min={5.0}
            max={14.0}
            step={1.0}
            defaultValue={7.0}
            name={sidewalk_length.name}
          />
        </Box>

        <Box sx={{ mt: 2 }}>
          <Typography gutterBottom>
            Tipp: Die Gehwegabsenkungslänge beschreibt hier wirklich nur die
            Länge des absenkten Bordsteins. Je nach Stadt-/Gemeindevorschriften
            muss der Gehweg über eine gewisse Länge mittels Flügelsteinen
            abgesenkt werden.
          </Typography>
        </Box>
      </Box>
    </Dialog>
  );
};
