import { signInWithEmailAndPassword } from "firebase/auth";

import { setDoc, doc } from "firebase/firestore";
import { auth, db } from "../firebaseConfig";

export const signIn = (auth, email, password) => {
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      return user;
    })
    .catch((error) => {});
};

export const saveUser = async (fullName) => {
  const user = auth.currentUser;

  if (user) {
    if (user.email) {
      await setDoc(doc(db, "users", user.uid), {
        fullName: fullName,
      });
    }
  }
};
