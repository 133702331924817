import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import React from "react";
import { WorkflowStateI } from "../views/WorkFlow";

interface ConstantFieldCompI {
  name: string;
  source: string;
  workflow: WorkflowStateI;
  onButtonClick: (value: string) => void;
  positions: string[];
}
export const ConstantFieldComp = (props: ConstantFieldCompI) => {
  return (
    <Card sx={{ maxWidth: 275, minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="primary" gutterBottom>
          KONSTANTE
        </Typography>
        <Typography variant="h6" component="div">
          <div>Name: {props.name} </div>
          <div>Konstante: {props.source} </div>
        </Typography>
        <div>
          Positionen:
          {props.positions &&
            props.positions.map((item, index) => {
              return <span key={index}> {item} </span>;
            })}
        </div>
      </CardContent>
      <CardActions>
        <Button
          onClick={() => {
            props.onButtonClick(props.name);
          }}
          size="small"
          variant="contained"
        >
          Position hinzufügen
        </Button>
      </CardActions>
    </Card>
  );
};
