import React, { createContext, useEffect, useState } from "react";
import { DashboardNav } from "./Client/components/DashboardNav";
import { SideWalkDialogView } from "./Client/Dialogs/Sidewalk/SidwalkDialogView";
import { Route, BrowserRouter, Routes, useNavigate } from "react-router-dom";
import { LoginView } from "./views/LoginView/LoginView";
import { ProtectedRoute } from "./Client/components/ProtectedRoute";
import { WorkFlow } from "./Admin/Workflow/views/WorkFlow";
import { OfferView } from "./Client/Dashboard/OfferView/OfferView";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { ProfileView } from "./Client/ProfileView/ProfileView";
import { RegistrationView } from "./views/RegistrationView/RegistrationView";
import { OfferProposal } from "./Client/Dashboard/OfferView/OfferProposal";
import { PositionUploadView } from "./Admin/Position/views/PositionUploadView";
import { AdminNav } from "./Admin/Menu/AdminNav";
import { AdminDashboard } from "./Admin/Dashboard/AdminDashboard";
import { AdminRoute } from "./Client/components/AdminRoute";
import { RedirectRoute } from "./Client/components/RedirectRoute";
import { OfferProposalViewer } from "./Admin/OfferProposalView/OfferProposalViewer";

export interface User {
  email: string;
  jwt: string;
}
export interface userContextProps {
  user: User | null | undefined;
  setUser: React.Dispatch<React.SetStateAction<User | null | undefined>>;
}

export const UserContext = createContext<userContextProps>({
  user: { email: "", jwt: "" },
  setUser: () => {},
});
function App() {
  const [user, setUser] = useState<User | null>();

  const theme = createTheme({
    typography: {
      fontFamily: ["Roboto", "sans-serif"].join(","),
    },
    palette: {
      primary: {
        main: "#212121",
        light: "#484848",
        dark: "#000000",
      },
      secondary: {
        main: "#ffffff",
        light: "ffffff",
        dark: "c7c7c7",
      },
    },
  });

  return (
    <div className="App">
      <UserContext.Provider value={{ user, setUser }}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <CssBaseline />
            <Routes>
              <Route
                path="/angebot"
                element={
                  <ProtectedRoute>
                    <>
                      <DashboardNav />
                      <SideWalkDialogView />
                    </>
                  </ProtectedRoute>
                }
              />

              <Route
                path="/profil"
                element={
                  <ProtectedRoute>
                    <>
                      <DashboardNav />
                      <ProfileView />
                    </>
                  </ProtectedRoute>
                }
              />

              <Route
                path="/angebote"
                element={
                  <RedirectRoute>
                    <>
                      <DashboardNav />
                      <OfferView />
                    </>
                  </RedirectRoute>
                }
              />

              <Route
                path="/angebotsaufforderung"
                element={
                  <ProtectedRoute>
                    <>
                      <DashboardNav />
                      <OfferProposal />
                    </>
                  </ProtectedRoute>
                }
              />
              <Route path="/" element={<LoginView />} />
              <Route
                path="/admin"
                element={
                  <AdminRoute>
                    <AdminNav />
                    <AdminDashboard />
                  </AdminRoute>
                }
              />
              <Route
                path="/lv"
                element={
                  <AdminRoute>
                    <AdminNav />
                    <PositionUploadView />
                  </AdminRoute>
                }
              />
              <Route
                path="/workflow"
                element={
                  <AdminRoute>
                    <AdminNav />
                    <WorkFlow />
                  </AdminRoute>
                }
              />

              <Route
                path="/offerProposalViewer"
                element={
                  <AdminRoute>
                    <AdminNav />
                    <OfferProposalViewer />
                  </AdminRoute>
                }
              />
              <Route path="/signup" element={<RegistrationView />} />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </UserContext.Provider>
    </div>
  );
}

export default App;
