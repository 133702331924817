export const sL = {
  SIDEWALK_LOCATION: "Bitte geben Sie den Ort ihres Bauvorhabens an:",
  SIDEWALK_PERMIT:
    " Laden Sie hier ihre Aufbruchgenehmigung hoch, die Sie von ihrer Stadt/Gemeinde erteilt bekommen haben.",

  SIDEWALK_BACKYARD:
    "Existiert zwischen ihrem Haus und dem Gehweg, welcher abgesenkt werden soll ein Vorgarten?",
  SIDWALK_OBSTACLES:
    "Gibt es verschiedene Hindernisse auf ihrem Gehweg, der abgesenkt werden soll?",
  SIDEWALK_SUCCESS:
    "Herzlichen Glückwunsch! Ihr Angebot wird nun erstellt. Dies kann ein bis zwei Tage dauern...",
};
