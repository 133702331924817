import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { WorkflowStateI, SourceFieldI } from "./WorkFlow";

interface SourceFieldViewProps {
  workflow: WorkflowStateI;
  onHandleClose: () => void;
}
export const SourcFieldView = (props: SourceFieldViewProps) => {
  const [source, setSource] = useState<string>("");

  const [sourceField, setSourceField] = useState<SourceFieldI>({
    name: "",
    source: "",
    positions: [],
  });

  const handleChange = (event: SelectChangeEvent) => {
    setSource(event.target.value);
    setSourceField({ ...sourceField, source: event.target.value });
  };
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSourceField({ ...sourceField, name: event.target.value });
  };

  const handleOk = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    props.workflow.setWorkflow({
      ...props.workflow.workflow,
      sourceFields: [...props.workflow.workflow.sourceFields, sourceField],
    });
    props.onHandleClose();
  };
  const dummy = ["sidewalk_length", "sidewalk_width"];
  return (
    <div>
      <TextField fullWidth label="Name" onChange={handleNameChange} />
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="demo-simple-select-label">Source</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={source}
          label="Source"
          onChange={handleChange}
        >
          {dummy.map((source, index) => {
            return (
              <MenuItem key={index} value={source}>
                {source}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Button
        sx={{ mt: 2 }}
        variant="contained"
        color="primary"
        onClick={handleOk}
      >
        Speichern
      </Button>
    </div>
  );
};
