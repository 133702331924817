/*street: string;
street_number: string;
city: string;
postalcode: string;
permits: string;
backyard: boolean;
lantern: boolean;
bollard: boolean;
watercap: boolean;
remarks: string;
frontview: string;
sideview: string;
topview: string;
sidewalk_length: string;
sidewalk_width: string;
flagstone_length: string;
flagstone_width: string;
cobblestone?: boolean;
flagstone?: boolean;
*/

export interface FormFieldObj {
  name: string;
  label: string;
  requiredErrorMsg: string;
}
export const sidewalkFormModel = {
  formField: {
    street: {
      name: "street",
      label: "Strasse",
      requiredErrorMsg: "Bitte geben Sie eine Strasse an.",
    },
    street_number: {
      name: "street_number",
      label: "Hausnummer",
      requiredErrorMsg: "Bitte geben Sie eine Hausnummer an.",
    },
    city: {
      name: "city",
      label: "Stadt",
      requiredErrorMsg: "Bitte geben Sie eine Stadt an.",
    },
    postalcode: {
      name: "postalcode",
      label: "PLZ",
      requiredErrorMsg: "Bitte geben Sie eine PLZ an.",
    },
    permits: {
      name: "permits",
      label: "Genehmigungen",
      requiredErrorMsg: "Bitte laden Sie ihre Genehmigung in PDF-Form hoch.",
    },
    backyard: {
      name: "backyard",
      label: "Vorgarten",
      requiredErrorMsg: "Bitte laden Sie ihre Genehmigung in PDF-Form hoch.",
    },
    lantern: {
      name: "lantern",
      label: "Laterne",
      requiredErrorMsg: "Bitte laden Sie ihre Genehmigung in PDF-Form hoch.",
    },
    bollard: {
      name: "bollard",
      label: "Pfeiler",
      requiredErrorMsg: "Bitte laden Sie ihre Genehmigung in PDF-Form hoch.",
    },
    watercap: {
      name: "watercap",
      label: "Wasserkappe",
      requiredErrorMsg: "Bitte laden Sie ihre Genehmigung in PDF-Form hoch.",
    },
    remarks: {
      name: "remarks",
      label: "Anmerkungen",
      requiredErrorMsg: "Bitte geben Sie eine Stadt an.",
    },
    frontview: {
      name: "frontview",
      label: "Frontalansicht",
      requiredErrorMsg:
        "Bitte laden Sie ein Foto ihres Gehweges in Frontalansicht hoch.",
    },
    sideview: {
      name: "sideview",
      label: "Seiteansicht",
      requiredErrorMsg:
        "Bitte laden Sie ein Foto ihres Gehweges in Seitenansicht hoch.",
    },
    sidewalkpavement: {
      name: "sidewalkpavement",
      label: "Gehwegbelag",
      requiredErrorMsg:
        "Bitte laden Sie ein Foto ihres Gehweges in Seitenansicht hoch.",
    },
    sidewalk_length: {
      name: "sidewalk_length",
      label: "Gehweglänge",
      requiredErrorMsg: "Bitte geben Sie eine gültige Gehweglänge an.",
    },
    sidewalk_width: {
      name: "sidewalk_width",
      label: "Gehwegbreite",
      requiredErrorMsg: "Bitte geben Sie eine gültige Gehwegbreite an.",
    },
  },
};
