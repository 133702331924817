import { TextField, TextFieldProps } from "@mui/material";
import { useField } from "formik";
import React from "react";

interface InputFieldProps {
  name: string;
}
const InputField = (props: TextFieldProps & InputFieldProps) => {
  const [field, meta] = useField(props.name);

  return (
    <TextField
      color="primary"
      sx={{ mb: 1 }}
      {...props}
      {...field}
      error={Boolean(meta.touched && meta.error)}
      helperText={meta.error && meta.touched ? meta.error : undefined}
    />
  );
};

export default InputField;
