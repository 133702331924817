import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Countdown, { CountdownRenderProps } from "react-countdown";
import {
  getCurrentOfferProposal,
  ValueProps,
} from "../../../services/offerService";
import { FieldValue } from "firebase/firestore";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import { Link } from "react-router-dom";

interface OfferProposalI {
  userId: string;
  offerData: ValueProps;
  timeStamp: FieldValue;
  status: number;
}
export const OpenOffers = () => {
  const countDownTime = 86400000;
  const [createdTime, setCreatedTime] = useState<number>();
  const [offerProposal, setOfferProposal] = useState<
    OfferProposalI | undefined
  >(undefined);

  const [loading, setLoading] = useState<boolean>(true);

  const isOfferCreated = () => {
    if (offerProposal) {
      if (offerProposal.status === 1) {
        return true;
      } else return false;
    } else return false;
  };
  const renderStatus = () => {
    if (offerProposal) {
      switch (offerProposal.status) {
        case 0:
          return (
            <Typography
              variant="h5"
              color="text.secondary"
              sx={{ mb: 1.5, mt: 3 }}
            >
              Status:{" "}
              <span style={{ color: "orange" }}>
                Angebotsanfrage in Bearbeitung
              </span>
            </Typography>
          );
        case 1:
          return (
            <Typography
              variant="h5"
              color="text.secondary"
              sx={{ mb: 1.5, mt: 3 }}
            >
              Status:{" "}
              <span style={{ color: "green" }}>Angebot bereitgestellt</span>
            </Typography>
          );
      }
    }
  };
  const renderer = ({
    hours,
    minutes,
    seconds,
    completed,
  }: CountdownRenderProps) => {
    if (completed) {
      return "Reday";
    } else {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          flexDirection="column"
          gap={2}
          sx={{ mt: 2, mb: 2 }}
        >
          <Typography sx={{}} variant="h6" color="text.secondary">
            <div>Die maximale Dauer beträgt: </div>
          </Typography>
          <div>
            <HourglassBottomIcon color="primary" />
            {hours}h {minutes}m {seconds}s
          </div>
          <Typography sx={{ mb: 1.5 }} variant="h6" color="text.secondary">
            <div>bis ihr Angebot erstellt wird.</div>
          </Typography>
        </Box>
      );
    }
  };

  const cardWithOffer = (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <Typography sx={{ mb: 1.5 }} variant="h5" color="primary">
          Hier erfahren Sie mehr über den Status ihrer Angebotsanfrage.
        </Typography>

        <Divider />
        {createdTime && !isOfferCreated() && (
          <Countdown date={createdTime + countDownTime} renderer={renderer} />
        )}
        <Divider />
        {renderStatus()}
      </CardContent>
      <CardActions>
        <Button
          component={Link}
          to="/angebotsaufforderung"
          disabled={!isOfferCreated()}
          variant="contained"
        >
          Zum Angebot
        </Button>
      </CardActions>
    </Card>
  );

  const cardWithoutOffer = (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          Angebotsstatus
        </Typography>
        <Typography variant="body2" sx={{ mb: 1.5 }}>
          Hier erfährst du den Status deiner Angebotsanfrage. Um gültige
          Informationen über deine Angebotsanfrage sehen zu können, musst du
          jedoch ersteinmal eine Angebotsanfrage erstellen.
        </Typography>
        <Typography color="text.secondary">
          Status:
          <Typography sx={{ color: "#f44336" }}>
            Angebotsanfrage noch nicht erstellt
          </Typography>
        </Typography>
      </CardContent>
    </Card>
  );
  useEffect(() => {
    const call = async () => {
      const offProp = await getCurrentOfferProposal();
      if (offProp) {
        setOfferProposal(offProp as OfferProposalI);
        setCreatedTime(offProp.timeStamp * 1000);
      }
      setLoading(false);
    };
    call();
  }, []);

  return loading ? (
    <CircularProgress color="primary" />
  ) : offerProposal ? (
    cardWithOffer
  ) : (
    cardWithoutOffer
  );
};
