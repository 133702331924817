import { RadioGroup } from "@mui/material";
import { useField, useFormikContext } from "formik";
import React from "react";

interface RadioGroupModule {
  name: string;
  defaultValue: string;
  children: React.ReactNode;
}
export const RadioGroupModule = (props: RadioGroupModule) => {
  const [field] = useField(props.name);
  const formikProps = useFormikContext();

  return (
    <RadioGroup
      name={props.name}
      defaultValue={props.defaultValue}
      value={field.value}
      onChange={(e) => {
        formikProps.setFieldValue(props.name, e.currentTarget.value);
      }}
    >
      {props.children}
    </RadioGroup>
  );
};
