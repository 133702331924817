import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

interface AlertDialogProps {
  openState: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  };
  handleClose: () => void;
  fireOnAgreeMethod: () => void;
  message: string;
  title: string;
}
export const AlertDialog = (props: AlertDialogProps) => {
  return (
    <div>
      <Dialog
        open={props.openState.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Zurück</Button>
          <Button
            onClick={() => {
              props.fireOnAgreeMethod();
              props.handleClose();
            }}
            color="error"
            autoFocus
          >
            Verstanden
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
