import { Checkbox, FormControlLabel } from "@mui/material";
import { useField } from "formik";
import React from "react";

interface CheckBoxProps {
  name: string;
  label: string;
}
export const CheckBox = (props: CheckBoxProps) => {
  const [field] = useField(props.name);
  return (
    <FormControlLabel
      label={props.label}
      control={
        <Checkbox
          name={props.name}
          checked={field.value}
          onChange={field.onChange}
          sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
        />
      }
    />
  );
};
