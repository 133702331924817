import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { WorkflowStateI, MultipliedFieldI } from "./WorkFlow";

interface MultipliedFieldViewProps {
  workflow: WorkflowStateI;
  onHandleClose: () => void;
}
export const MultipliedFieldView = (props: MultipliedFieldViewProps) => {
  const [multipliedField, setMultipliedField] = useState<MultipliedFieldI>({
    name: "",
    sourceOne: "",
    sourceTwo: "",
    positions: [],
  });

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMultipliedField({ ...multipliedField, name: e.target.value });
  };

  const handleSourceOneChange = (e: SelectChangeEvent) => {
    setMultipliedField({ ...multipliedField, sourceOne: e.target.value });
  };

  const handleSourceTwoChange = (e: SelectChangeEvent) => {
    setMultipliedField({ ...multipliedField, sourceTwo: e.target.value });
  };

  const saveField = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    props.workflow.setWorkflow({
      ...props.workflow.workflow,
      multipliedFields: [
        ...props.workflow.workflow.multipliedFields,
        multipliedField,
      ],
    });
    props.onHandleClose();
  };
  return (
    <div>
      <TextField fullWidth label="Name" onChange={handleNameChange} />
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="demo-simple-select-label">Source</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={multipliedField.sourceOne}
          label="Source 1"
          onChange={handleSourceOneChange}
        >
          {props.workflow.workflow.sourceFields.map((source, index) => {
            return (
              <MenuItem key={index} value={source.name}>
                {source.name}
              </MenuItem>
            );
          })}

          {props.workflow.workflow.multipliedFields.map((source, index) => {
            return (
              <MenuItem key={index} value={source.name}>
                {source.name}
              </MenuItem>
            );
          })}

          {props.workflow.workflow.constantFields.map((source, index) => {
            return (
              <MenuItem key={index} value={source.name}>
                {source.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="demo-simple-select-label">Source</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={multipliedField.sourceTwo}
          label="Source 1"
          onChange={handleSourceTwoChange}
        >
          {props.workflow.workflow.sourceFields.map((source, index) => {
            return (
              <MenuItem key={index} value={source.name}>
                {source.name}
              </MenuItem>
            );
          })}

          {props.workflow.workflow.multipliedFields.map((source, index) => {
            return (
              <MenuItem key={index} value={source.name}>
                {source.name}
              </MenuItem>
            );
          })}

          {props.workflow.workflow.constantFields.map((source, index) => {
            return (
              <MenuItem key={index} value={source.name}>
                {source.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Button color="primary" onClick={saveField} variant="contained">
        Speichern
      </Button>
    </div>
  );
};
