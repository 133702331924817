import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  Typography,
} from "@mui/material";
import React from "react";
import { Dialog } from "../../components/Dialog";
import { RadioGroupModule } from "../../components/RadioGroupModule";
import { sidewalkFormModel } from "../../../FormikModels/sidewalkFormModel";

export const SideWalkSpecDialog = () => {
  const {
    formField: { sidewalkpavement },
  } = sidewalkFormModel;

  return (
    <Dialog>
      <Typography
        sx={{
          fontSize: {
            lg: 30,
            md: 20,
            sm: 20,
            xs: 20,
          },
        }}
        variant="h6"
      >
        Welche Art von Bodenbelag befindet sich auf ihrem Gehweg?
      </Typography>

      <Box
        sx={{ mt: 2 }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <FormControl>
          <FormLabel>Bodenbelag</FormLabel>
          <RadioGroupModule
            defaultValue="Pflaster"
            name={sidewalkpavement.name}
          >
            <FormControlLabel
              value="Pflaster"
              control={<Radio />}
              label="Pflaster"
            />
            <FormControlLabel
              value="Platten"
              control={<Radio />}
              label="Platten"
            />
            <FormControlLabel
              value="Asphalt"
              control={<Radio />}
              label="Asphalt"
            />
            <FormControlLabel
              value="Unbefestigt"
              control={<Radio />}
              label="Unbefestigt"
            />
          </RadioGroupModule>
        </FormControl>
      </Box>
    </Dialog>
  );
};
