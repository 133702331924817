import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { MultipliedFieldComp } from "../components/MultipliedFieldComp";
import { MultipliedFieldView } from "./MultipliedFieldView";
import { ConstantFieldView } from "./ConstantFieldView";
import { SourceFieldComp } from "../components/SourceFieldComp";
import { SourcFieldView } from "./SourcFieldView";
import { BooleanFieldComp } from "../components/BooleanFieldComp";
import { BooleanFieldView } from "./BooleanFieldView";
import { usePositions } from "../hooks/usePositions";
import { PositionPicker } from "./PositionPicker";
import { saveWorkflow } from "../../../services/worfklowService";
import { ConstantFieldComp } from "../components/ConstantFieldComp";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export const dummy = {
  sidewalk_length: 700,
  sidewalk_widht: 160,
  sidewalk_quadrat_meter: 11.2,
  sidewalk_kubik_meter: 7.4,
  pavement: true,
  backyard: true,
  lantern: true,
};

const style = {
  position: "absolute" as "absolute",
  top: "20%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
export interface WorkflowI {
  name: string;
  sourceFields: SourceFieldI[];
  multipliedFields: MultipliedFieldI[];
  constantFields: ConstantFieldI[];
  booleanFields: BooleanFieldI[];
}

export interface SourceFieldI {
  name: string;
  source: string;
  positions: string[];
  value?: number;
}

export interface MultipliedFieldI {
  name: string;
  sourceOne: string;
  sourceTwo: string;
  positions: string[];
  value?: number;
}

export interface BooleanFieldI {
  name: string;
  source: string;
  multiplyField: string;
  positions: string[];
  value?: number;
}

export interface ConstantFieldI {
  type: string;
  name: string;
  source: string;
  positions: string[];
  value?: number;
}

interface ErrorI {
  error: boolean;
  errorMessage: string;
}

export interface WorkflowStateI {
  workflow: WorkflowI;
  setWorkflow: React.Dispatch<React.SetStateAction<WorkflowI>>;
}
export const WorkFlow = () => {
  const [workflow, setWorkflow] = useState<WorkflowI>({
    name: "",
    sourceFields: [],
    multipliedFields: [],
    booleanFields: [],
    constantFields: [],
  });
  const [error, setError] = useState<ErrorI>();
  const [openPositionPicker, setOpenPositionPicker] = useState<boolean>(false);
  const [workflowName, setWorkflowName] = useState<string>();
  const [activeField, setActiveField] = useState<string>("");

  const [openSourceField, setOpenSourceField] = React.useState(false);
  const [openMultipliedField, setOpenMultipliedField] = React.useState(false);
  const [openBooleanField, setOpenBooleanField] = React.useState(false);
  const [openConstantField, setOpenConstantField] = React.useState(false);

  const handleOpenSoureField = () => setOpenSourceField(true);
  const handleOpenMultipliedField = () => setOpenMultipliedField(true);
  const handleOpenBooleanField = () => setOpenBooleanField(true);
  const handleOpenConstantField = () => setOpenConstantField(true);

  const handleClose = () => setOpenSourceField(false);
  const handleCloseMultipliedField = () => setOpenMultipliedField(false);
  const handleCloseBooleanField = () => setOpenBooleanField(false);
  const handleCloseConstantField = () => setOpenConstantField(false);

  const { positions } = usePositions();

  const onFieldButtonClick = () => {
    setOpenPositionPicker(true);
  };

  /*HandleClose for PositionPicker*/
  const onHandleClose = (value: string, fieldName: string) => {
    setOpenPositionPicker(false);
    const newSourceFields = workflow.sourceFields;
    const newMultipliedFields = workflow.multipliedFields;
    const newBooleanFields = workflow.booleanFields;
    const newConstantFields = workflow.constantFields;

    const indexSource = newSourceFields.findIndex((item, id) => {
      if (item.name === fieldName) {
        return true;
      }
    });
    if (indexSource !== -1) newSourceFields[indexSource].positions.push(value);

    const indexMultiplied = newMultipliedFields.findIndex((item, id) => {
      if (item.name === fieldName) return true;
    });
    if (indexMultiplied !== -1)
      newMultipliedFields[indexMultiplied].positions.push(value);

    const indexBool = newBooleanFields.findIndex((item, id) => {
      if (item.name === fieldName) {
        return true;
      } else return false;
    });
    if (indexBool !== -1) newBooleanFields[indexBool].positions.push(value);

    const indexConstant = newConstantFields.findIndex((item, id) => {
      if (item.name === fieldName) {
        return true;
      } else return false;
    });
    if (indexConstant !== -1)
      newConstantFields[indexBool].positions.push(value);

    setWorkflow({
      ...workflow,
      sourceFields: newSourceFields,
      multipliedFields: newMultipliedFields,
      booleanFields: newBooleanFields,
      constantFields: newConstantFields,
    });
  };

  return (
    <div>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="end"
        gap={2}
        sx={{ p: 2, backgroundColor: "#212121" }}
      >
        {workflow.name !== "" && (
          <IconButton
            onClick={() => {
              setWorkflow({
                name: "",
                sourceFields: [],
                multipliedFields: [],
                booleanFields: [],
                constantFields: [],
              });
            }}
          >
            <DeleteForeverIcon color="error" />
          </IconButton>
        )}

        <TextField
          color="secondary"
          size="small"
          label="Workflow"
          sx={{ input: { color: "white" } }}
          onChange={(e) => {
            setWorkflowName(e.target.value);
          }}
          variant="standard"
          InputLabelProps={{
            style: { color: "#fff" },
          }}
        />
        <Button
          variant="contained"
          color="success"
          disabled={workflow.name !== "" ? true : false}
          onClick={(e) => {
            if (workflowName) {
              setWorkflow({
                name: workflowName,
                sourceFields: [...workflow.sourceFields],
                multipliedFields: [...workflow.multipliedFields],
                booleanFields: [...workflow.booleanFields],
                constantFields: [...workflow.constantFields],
              });
            } else {
              setError({
                error: true,
                errorMessage: "Bitte einen Workflownamen angeben.",
              });
            }
          }}
        >
          Neuer Workflow
        </Button>

        <Button
          variant="contained"
          color="success"
          onClick={(e) => {
            handleOpenSoureField();
          }}
        >
          SourceField hinzufügen
        </Button>

        <Button
          variant="contained"
          color="success"
          onClick={(e) => {
            handleOpenMultipliedField();
          }}
        >
          MultipliedField hinzufügen
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={(e) => {
            handleOpenBooleanField();
          }}
        >
          BooleanField hinzufügen
        </Button>

        <Button
          variant="contained"
          color="success"
          onClick={(e) => {
            handleOpenConstantField();
          }}
        >
          ConstantField hinzufügen
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={(e) => {
            saveWorkflow(workflow);
          }}
        >
          Speichern
        </Button>
        {error && <Alert severity="error">{error.errorMessage}</Alert>}
      </Box>

      <Box sx={{ m: 2 }}>
        {workflow.name !== "" && (
          <Typography sx={{ color: "white" }} variant="h4">
            {workflow.name}
          </Typography>
        )}

        <Grid container spacing={2} sx={{ mt: 2 }}>
          {workflow.sourceFields.map((data, index) => {
            return (
              <Grid item>
                <SourceFieldComp
                  name={data.name}
                  source={data.source}
                  workflow={{ workflow, setWorkflow }}
                  positions={data.positions}
                  onButtonClick={() => {
                    onFieldButtonClick();
                    setActiveField(data.name);
                  }}
                />
              </Grid>
            );
          })}
        </Grid>

        <Grid container spacing={2} sx={{ mt: 2 }}>
          {workflow.constantFields.map((data, index) => {
            return (
              <Grid item>
                <ConstantFieldComp
                  name={data.name}
                  source={data.source}
                  workflow={{ workflow, setWorkflow }}
                  positions={data.positions}
                  onButtonClick={() => {
                    onFieldButtonClick();
                    setActiveField(data.name);
                  }}
                />
              </Grid>
            );
          })}
        </Grid>

        <Grid container spacing={2} sx={{ mt: 2 }}>
          {workflow.multipliedFields.map((data, index) => {
            return (
              <Grid item>
                <MultipliedFieldComp
                  name={data.name}
                  sourceOne={data.sourceOne}
                  sourceTwo={data.sourceTwo}
                  workflow={{ workflow, setWorkflow }}
                  positions={data.positions}
                  onButtonClick={() => {
                    onFieldButtonClick();
                    setActiveField(data.name);
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {workflow.booleanFields.map((data, index) => {
            return (
              <Grid item>
                <BooleanFieldComp
                  name={data.name}
                  source={data.source}
                  multiplyField={data.multiplyField}
                  workflow={{ workflow, setWorkflow }}
                  positions={data.positions}
                  onButtonClick={() => {
                    onFieldButtonClick();
                    setActiveField(data.name);
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Modal
        open={openSourceField}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <SourcFieldView
            workflow={{ workflow, setWorkflow }}
            onHandleClose={handleClose}
          />
        </Box>
      </Modal>

      <Modal
        open={openMultipliedField}
        onClose={handleCloseMultipliedField}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <MultipliedFieldView
            workflow={{ workflow, setWorkflow }}
            onHandleClose={handleCloseMultipliedField}
          />
        </Box>
      </Modal>

      <Modal
        open={openBooleanField}
        onClose={handleCloseBooleanField}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <BooleanFieldView
            workflow={{ workflow, setWorkflow }}
            onHandleClose={handleCloseBooleanField}
          />
        </Box>
      </Modal>

      <Modal
        open={openConstantField}
        onClose={handleCloseConstantField}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ConstantFieldView
            workflow={{ workflow, setWorkflow }}
            onHandleClose={handleCloseConstantField}
          />
        </Box>
      </Modal>
      <PositionPicker
        open={openPositionPicker}
        selectedValue={activeField}
        positions={positions}
        onClose={onHandleClose}
      />
    </div>
  );
};
