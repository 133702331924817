import { Box } from "@mui/material";
import React from "react";

interface DialogProps {
  children: React.ReactNode;
}
export const Dialog = (props: DialogProps) => {
  return (
    <Box
      sx={{ p: 4 }}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      {props.children}
    </Box>
  );
};
