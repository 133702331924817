import { registrationFormModel } from "./registrationFormModel";

const {
  formField: { email, password, fullName },
} = registrationFormModel;
export const registrationInitialValues = {
  [email.name]: "",
  [password.name]: "",
  [fullName.name]: "",
};
