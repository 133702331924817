import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import React from "react";
import { WorkflowStateI } from "../views/WorkFlow";

interface BooleanFieldCompProps {
  name: string;
  source: string;
  multiplyField: string;
  workflow: WorkflowStateI;
  positions: string[];
  onButtonClick: (value: string) => void;
}
export const BooleanFieldComp = (props: BooleanFieldCompProps) => {
  return (
    <Card sx={{ maxWidth: 275, minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} gutterBottom>
          BOOLEANFIELD
        </Typography>
        <Typography variant="h6" component="div">
          <div>Name: {props.name} </div>
          <div>Source: {props.source} </div>
          <div>MultiplieField: {props.multiplyField} </div>
        </Typography>
        <div>
          Positionen:
          {props.positions &&
            props.positions.map((item, index) => {
              return <span key={index}> {item} </span>;
            })}
        </div>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          onClick={() => {
            props.onButtonClick(props.name);
          }}
          variant="contained"
        >
          Position hinzufügen
        </Button>
      </CardActions>
    </Card>
  );
};
