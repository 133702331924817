import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  ImageList,
  ImageListItem,
  Link,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import {
  getCurrentOfferProposal,
  getCurrentOffer,
  deleteCurrentOffer,
  deleteCurrentOfferProposal,
  getUsername,
} from "../../../services/offerService";
import { Position } from "../../../Admin/Position/components/CsvImport";
import { AlertDialog } from "../../components/AlertDialog";
import { useLocation, useNavigate } from "react-router-dom";
import { auth } from "../../../firebaseConfig";

export interface OfferProposalFireStoreI {
  id: string;
  offerProposalData: {
    city: string;
    postalcode: string;
    street: string;
    street_number: string;
    permits: string;
    lantern: boolean;
    backyard: boolean;
    bollard: boolean;
    watercap: boolean;
    frontview: string;
    sideview: string;
    sidewalk_length: number;
    sidewalk_width: number;
    sidewalkpavement: string;
  };
  status: number;
  timeStamp: string;
}

interface OfferFireStoreI {
  bruttoGesamtPreis: number;
  positions: Position[];
  status: number;
}
export const OfferProposal = () => {
  const dummy = {
    street: "Hoemenstrasse 40",
    fullName: "Max Mustermann",
    price: 4490.16,
    sidewalkSurface: 30,
    backyard: true,
    lantern: true,
    watercap: true,
    sidewalk_width: 140,
    sidewalk_length: 700,
    sidewalkpavement: "Platten",
  };

  const [offerProposal, setOfferProposal] = useState<
    OfferProposalFireStoreI | undefined
  >();
  const [userName, setUsername] = useState<string>();
  const [open, setOpen] = useState<boolean>(false);
  const [offer, setOffer] = useState<OfferFireStoreI | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const fetchUsername = async () => {
    const uid = auth.currentUser?.uid;
    const data = await getUsername(uid!);
    if (data) {
      setUsername(data.fullName);
    }
  };
  const fetchOfferProposal = async () => {
    const data = await getCurrentOfferProposal();
    if (data) {
      setOfferProposal({
        id: data.id,
        offerProposalData: {
          city: data.offerProposalData.city,
          postalcode: data.offerProposalData.postalcode,
          street: data.offerProposalData.street,
          street_number: data.offerProposalData.street_number,
          permits: data.offerProposalData.permits,
          lantern: data.offerProposalData.lantern,
          backyard: data.offerProposalData.backyard,
          bollard: data.offerProposalData.bollard,
          watercap: data.offerProposalData.watercap,
          frontview: data.offerProposalData.frontview,
          sideview: data.offerProposalData.sideview,
          sidewalk_length: data.offerProposalData.sidewalk_length,
          sidewalk_width: data.offerProposalData.sidewalk_width,
          sidewalkpavement: data.offerProposalData.sidewalkpavement,
        },
        status: data.status,
        timeStamp: data.timeStamp,
      });
    }
  };

  const fetchOffer = async () => {
    const data = await getCurrentOffer();
    if (data) {
      setOffer({
        bruttoGesamtPreis: data.bruttoGesamtPreis,
        positions: data.positions,
        status: data.status,
      });
    }
  };
  useEffect(() => {
    setLoading(true);
    fetchOfferProposal();
    fetchUsername();
    fetchOffer();
    setLoading(false);
  }, []);

  return (
    <Container
      maxWidth="md"
      sx={{
        boxShadow: 4,
        my: 5,
        p: 5,
        backgroundColor: "white",
        borderRadius: 2,
      }}
    >
      {loading && <CircularProgress color="primary" />}
      {offerProposal && offer && (
        <>
          <Typography variant="h5" color="primary" sx={{ mb: 5 }}>
            Bauvorhaben:{" "}
            {offerProposal.offerProposalData.street +
              " " +
              offerProposal.offerProposalData.street_number}
          </Typography>

          <Box>
            <Typography variant="h5" sx={{ my: 1 }}>
              <BorderColorIcon color="primary" /> Angebot
            </Typography>
            <Divider component="div" variant="middle" />

            <Box
              display="display"
              flexDirection="column"
              justifyContent="center"
              sx={{ p: 1 }}
            >
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Sehr geehrte Frau/Herr {userName && userName}
              </Typography>

              <Typography variant="body1">
                Wir freuen uns Ihnen ein Angebot unterbreiten zu können.
              </Typography>

              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", mt: 2, mb: 2 }}
              >
                Für dieses Bauvorhaben wurde eine Summe von:{" "}
                <span style={{ color: "green" }}>
                  {offer.bruttoGesamtPreis.toFixed(2)} Euro
                </span>{" "}
                pauschal für Sie errechnet.{" "}
              </Typography>

              <Typography variant="body1">
                An unser Angebot halten wir uns 3 Monate gebunden. Für Aufmass
                und Abrechnung gelten Teil B und Teil C der VOB in der neuesten
                Fassung. Das Zahlungsziel ist sofort nach Rechnungserhalt. Falls
                Sie das Angebot bestätigen, werden wir Uns schnellstmöglich um
                ihr Bauvorhaben kümmern.
              </Typography>

              <Box display="flex" gap={2} justifyContent="center" sx={{ m: 2 }}>
                <Button variant="outlined" color="success">
                  Annehmen
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Ablehnen
                </Button>
              </Box>
            </Box>
          </Box>

          <Box>
            <Typography variant="h5" sx={{ my: 1 }}>
              <BorderColorIcon color="primary" /> Bauspezifische Informationen
            </Typography>
            <Divider component="div" variant="middle" />

            <Box
              display="display"
              flexDirection="column"
              justifyContent="center"
              sx={{ p: 1 }}
            >
              <Typography variant="body1">
                <b>Gehwegfläche:</b>{" "}
                {(
                  offerProposal.offerProposalData.sidewalk_length *
                  offerProposal.offerProposalData.sidewalk_width
                ).toFixed(2)}{" "}
                m²
              </Typography>
              <Typography variant="body1">
                <b>Vorgarten:</b>{" "}
                {offerProposal.offerProposalData.backyard ? "Ja" : "Nein"}
              </Typography>

              <Typography variant="body1">
                <b>Hindernisse:</b>{" "}
                {offerProposal.offerProposalData.lantern ? "Laterne" : ""},{" "}
                {offerProposal.offerProposalData.watercap ? "Wasserkappe" : ""},{" "}
                {offerProposal.offerProposalData.bollard ? "Pöller" : ""}
              </Typography>

              <Typography variant="body1">
                <b>Gehweglänge:</b>{" "}
                {offerProposal.offerProposalData.sidewalk_length.toFixed(2)} m
              </Typography>
              <Typography variant="body1">
                <b>Gehwegbreite:</b>{" "}
                {offerProposal.offerProposalData.sidewalk_width.toFixed(2)} m
              </Typography>
              <Typography variant="body1">
                <b>Bodenbelag:</b>{" "}
                {offerProposal.offerProposalData.sidewalkpavement}
              </Typography>
            </Box>
          </Box>

          <Box>
            <Typography variant="h5" sx={{ my: 1 }}>
              <BorderColorIcon color="primary" /> Downloads
            </Typography>
            <Divider component="div" variant="middle" />
            <List>
              <ListItem>
                <Link
                  href={offerProposal.offerProposalData.permits}
                  target="_blank"
                >
                  Genehmigung
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  href={offerProposal.offerProposalData.frontview}
                  target="_blank"
                >
                  Seitenansicht
                </Link>
              </ListItem>

              <ListItem>
                <Link
                  href={offerProposal.offerProposalData.sideview}
                  target="_blank"
                >
                  Frontansicht
                </Link>
              </ListItem>
            </List>
          </Box>

          <Box>
            <Typography variant="h5" sx={{ my: 1 }}>
              <BorderColorIcon color="primary" /> Gehwegbilder
            </Typography>
            <Divider component="div" variant="middle" />

            <ImageList>
              <ImageListItem>
                <img src={offerProposal.offerProposalData.sideview} />
              </ImageListItem>

              <ImageListItem>
                <img src={offerProposal.offerProposalData.frontview} />
              </ImageListItem>
            </ImageList>
          </Box>
        </>
      )}
      <AlertDialog
        openState={{ open, setOpen }}
        handleClose={() => {
          setOpen(false);
        }}
        fireOnAgreeMethod={() => {
          deleteCurrentOfferProposal();
          navigate("/angebote");
        }}
        message="Wenn Sie das Angebot ablehnen, wird ihr Angebot unwiderruflich gelöscht. Sind Sie sich sicher?"
        title="Achtung!"
      />
    </Container>
  );
};
