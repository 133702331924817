import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DashboardHeader } from "../../components/DashboardHeader";
import WorkIcon from "@mui/icons-material/Work";
import { Link } from "react-router-dom";
import { OpenOffers } from "./OpenOffers";
export const OfferView = () => {
  const [started] = useState<boolean>(true);

  useEffect(() => {}, []);

  const firstTimeCard = (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DashboardHeader icon={<WorkIcon color="primary" />}>
            Erstellen Sie ihr personalisiertes Angebot in nur ein paar
            Schritten.
          </DashboardHeader>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card sx={{ mt: 2 }}>
            <CardMedia
              component="video"
              src="/gehwegabsenkung2.mp4"
              autoPlay
              loop
              muted
              playsInline
            />
            <CardContent>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                Gehwegabsenkung
              </Typography>
              <Typography variant="body2">
                In ein paar Schritten kannst du hier dein Angebot einfordern. Es
                wird so schnell wie möglich für dich erstellt!
              </Typography>
            </CardContent>
            <CardActions>
              <Button component={Link} variant="contained" to="/angebot">
                Angebot einholen
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} sx={{ mb: 5 }}>
          <OpenOffers />
        </Grid>
      </Grid>
    </>
  );

  return <Container>{started && firstTimeCard}</Container>;
};
