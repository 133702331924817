import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React from "react";
import { Position } from "../../Position/components/CsvImport";

export interface PositionPickerProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string, fieldName: string) => void;
  positions: Position[] | undefined;
}
export const PositionPicker = (props: PositionPickerProps) => {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue, "");
  };

  const handleListItemClick = (value: string, fieldName: string) => {
    onClose(value, fieldName);
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
      <DialogTitle>Positionen</DialogTitle>

      <List>
        {props.positions &&
          props.positions.map((item, index) => {
            return (
              <ListItem
                autoFocus
                button
                key={item.nr}
                onClick={() => handleListItemClick(item.nr, selectedValue)}
              >
                <ListItemText>{item.nr}</ListItemText>
                <ListItemText>{item.text}</ListItemText>
                <ListItemText>{item.einheit}</ListItemText>
              </ListItem>
            );
          })}
      </List>
    </Dialog>
  );
};
