import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { WorkflowStateI, ConstantFieldI } from "./WorkFlow";

interface ConstantFieldViewProps {
  workflow: WorkflowStateI;
  onHandleClose: () => void;
}
export const ConstantFieldView = (props: ConstantFieldViewProps) => {
  const [source, setSource] = useState<string>("");

  const [constantField, setConstantField] = useState<ConstantFieldI>({
    name: "",
    source: "",
    positions: [],
    type: "Zahl",
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSource(event.target.value);
    setConstantField({ ...constantField, source: event.target.value });
  };
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConstantField({ ...constantField, name: event.target.value });
  };

  const handleOk = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    props.workflow.setWorkflow({
      ...props.workflow.workflow,
      constantFields: [
        ...props.workflow.workflow.constantFields,
        constantField,
      ],
    });
    props.onHandleClose();
  };

  return (
    <div>
      <TextField fullWidth label="Name" onChange={handleNameChange} />
      <FormControl fullWidth sx={{ mt: 2 }}>
        <TextField
          type="number"
          value={source}
          onChange={handleChange}
        ></TextField>
      </FormControl>
      <Button color="secondary" onClick={handleOk}>
        Speichern
      </Button>
    </div>
  );
};
