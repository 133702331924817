import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { savePositions } from "../../../services/positionService";
import { moneyConverter } from "../../../utils/helpers";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
export interface Position {
  nr: string;
  einheit: string;
  text: string;
  price: number;
  menge: number;
}
interface CsvImportProps {
  positions: Position[] | undefined;
  setPositions: React.Dispatch<React.SetStateAction<Position[] | undefined>>;
}

export const dummy = {
  sidewalk_length: 700,
  sidewalk_widht: 160,
  sidewalk_quadrat_meter: 11.2,
  sidewalk_kubik_meter: 7.4,
  pavement: true,
  backyard: true,
  lantern: true,
};

export const CsvImport = (props: CsvImportProps) => {
  const [file, setFile] = useState<File>();
  const [loading, setLoading] = useState<Boolean>(false);
  const fileReader = new FileReader();
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.target && e.target.files) {
      setFile(e.target.files[0]);
    }
  };
  const handleSubmitPositions = () => {
    setLoading(true);
    if (props.positions) {
      savePositions(props.positions);
    }
    setLoading(false);
  };
  const csvFileToArray = (text: string) => {
    let pos = [] as Position[];
    const csvRows = text.slice(text.indexOf("\n") + 1).split("\n");
    csvRows.pop();
    csvRows.map((data) => {
      const values = data.split(";");
      pos.push({
        nr: values[0],
        einheit: values[1],
        text: values[2],
        price: 0,
        menge: 0,
      });
      return values;
    });

    props.setPositions(pos);
  };
  const handleOnSubmit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    if (file) {
      fileReader.onload = function (event) {
        if (event && event.target) {
          const csvOutput = event.target.result;
          if (csvOutput) csvFileToArray(csvOutput as string);
        }
      };

      fileReader.readAsText(file);
    }
  };

  const handleOnBlur = (price: number, nr: string) => {
    if (props.positions) {
      const newPositions = [...props.positions];
      const index = newPositions.findIndex((item, i) => {
        if (item.nr === nr) {
          return true;
        } else return false;
      });
      newPositions[index].price = price;
      props.setPositions(() => [...newPositions]);
    }
  };
  return (
    <Box sx={{ m: 2 }}>
      <Box>
        <Typography variant="h4" sx={{ color: "white" }}>
          LV Upload
        </Typography>

        <form>
          <Box>
            <Button
              sx={{ mx: 2 }}
              component="label"
              color="success"
              variant="contained"
            >
              CSV auswählen
              <input
                type={"file"}
                id={"csvFileInput"}
                accept={".csv"}
                onChange={handleOnChange}
                hidden
              />
            </Button>

            <Button
              onClick={(e) => {
                handleOnSubmit(e);
              }}
              variant="contained"
            >
              IMPORT CSV
            </Button>

            {props.positions && (
              <IconButton
                onClick={() => {
                  props.setPositions(undefined);
                }}
              >
                <DeleteForeverIcon color="error" />
              </IconButton>
            )}
          </Box>
        </form>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: "white" }} align="left">
                  Position
                </TableCell>
                <TableCell sx={{ color: "white" }} align="left">
                  Einheit
                </TableCell>
                <TableCell sx={{ color: "white" }} align="left">
                  Text
                </TableCell>
                <TableCell sx={{ color: "white" }} align="left">
                  Preis in €
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.positions &&
                props.positions.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      sx={{ color: "white" }}
                      component="th"
                      scope="row"
                    >
                      {row.nr}
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="left">
                      {row.einheit}
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="left">
                      {row.text}
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="left">
                      <CurrencyInput
                        style={{
                          fontSize: "18px",
                        }}
                        defaultValue={0.0}
                        decimalsLimit={2}
                        groupSeparator={" "}
                        decimalSeparator={","}
                        intlConfig={{ locale: "de-DE" }}
                        onBlur={(e) => {
                          handleOnBlur(moneyConverter(e.target.value), row.nr);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box display="flex" justifyContent="end" sx={{ p: 2 }}>
          {" "}
          <Button
            color="secondary"
            variant="contained"
            onClick={handleSubmitPositions}
          >
            {loading ? <CircularProgress /> : "Leistungsverzeichnis Speichern"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
