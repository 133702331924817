import React, { useContext } from "react";
import { UserContext, userContextProps } from "../../App";
import jwt_decode from "jwt-decode";
import { Navigate } from "react-router-dom";
interface ProtectedRouteProps {
  children: React.ReactNode;
}
export const RedirectRoute = (props: ProtectedRouteProps) => {
  const email = localStorage.getItem("user");
  const jwt = localStorage.getItem("jwt");

  if (jwt === null || email === undefined) {
    return <Navigate to="/" replace />;
  } else {
    const decodedJwt: any = jwt_decode(jwt);
    if (decodedJwt.custom_claims && decodedJwt.custom_claims[0] === "ADMIN") {
      return <Navigate to="/admin" replace />;
    } else {
      return <div>{props.children}</div>;
    }
  }
};
