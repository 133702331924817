import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";

export const DashboardNav = () => {
  const jwt = localStorage.getItem("jwt");

  const handleLogout = () => {
    localStorage.removeItem("jwt");
    navigate("/");
  };
  const navigate = useNavigate();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            PortlyApp v2.0
          </Typography>

          <Button color="inherit" component={Link} to="/angebote">
            Angebote
          </Button>
          <Button color="inherit" component={Link} to="/profil">
            Profil
          </Button>

          <Button color="inherit" component={Link} to="/impressum">
            Impressum
          </Button>
          {jwt && (
            <Button color="inherit" onClick={handleLogout}>
              Logout
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <div style={{ margin: "1rem", color: "white" }}>
        Momentan befindet sich Portly in der Testphase. Bei Fragen kontaktieren
        Sie uns gerne unter <b> help@portly.de</b>
      </div>
    </Box>
  );
};
