import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import { SourceFieldI, WorkflowStateI } from "../views/WorkFlow";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

interface SourceFieldCompI {
  name: string;
  source: string;
  workflow: WorkflowStateI;
  onButtonClick: (value: string) => void;
  positions: string[];
}
export const SourceFieldComp = (props: SourceFieldCompI) => {
  return (
    <Card sx={{ maxWidth: 275, minWidth: 275 }}>
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <Typography sx={{ fontSize: 14 }} color="primary" gutterBottom>
            SOURCEFIELD
          </Typography>

          <IconButton onClick={() => {}}>
            <DeleteForeverIcon color="error" />
          </IconButton>
        </Box>

        <Typography variant="h6" component="div">
          <div>Name: {props.name} </div>
          <div>Source: {props.source} </div>
        </Typography>
        <div>
          Positionen:
          {props.positions &&
            props.positions.map((item, index) => {
              return <span key={index}> {item} </span>;
            })}
        </div>
      </CardContent>
      <CardActions>
        <Button
          onClick={() => {
            props.onButtonClick(props.name);
          }}
          size="small"
          variant="contained"
        >
          Position hinzufügen
        </Button>
      </CardActions>
    </Card>
  );
};
