import { Box, Typography } from "@mui/material";
import React from "react";
import { CheckBox } from "../../components/CheckBox";
import { Dialog } from "../../components/Dialog";
import { sL } from "./sidewalkLanguage";
import { sidewalkFormModel } from "../../../FormikModels/sidewalkFormModel";

const BackyardDialog = () => {
  const {
    formField: { backyard },
  } = sidewalkFormModel;
  return (
    <Dialog>
      <Typography
        sx={{
          fontSize: {
            lg: 30,
            md: 20,
            sm: 20,
            xs: 20,
          },
        }}
        variant="h6"
      >
        {sL.SIDEWALK_BACKYARD}
      </Typography>
      <Box
        sx={{ mt: 2 }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CheckBox name={backyard.name} label="Ja" />
      </Box>
    </Dialog>
  );
};

export default BackyardDialog;
