import * as yup from "yup";
import { registrationFormModel } from "./registrationFormModel";
const {
  formField: { email, password, fullName },
} = registrationFormModel;

export const registrationFormValidationSchema = [
  yup.object().shape({
    [email.name]: yup.string().email().required(email.requiredErrorMsg),
    [password.name]: yup.string().required(password.requiredErrorMsg),
    [fullName.name]: yup.string().required(fullName.requiredErrorMsg),
  }),
];
