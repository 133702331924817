import { sidewalkFormModel, FormFieldObj } from "./sidewalkFormModel";

export interface FormField {
  street: FormFieldObj;
  street_number: FormFieldObj;
  city: FormFieldObj;
  postalcode: FormFieldObj;
  permits: FormFieldObj;
  backyard: FormFieldObj;
  lantern: FormFieldObj;
  bollard: FormFieldObj;
  watercap: FormFieldObj;
  remarks: FormFieldObj;
  frontview: FormFieldObj;
  sideview: FormFieldObj;
  topview: FormFieldObj;
  cobblestone: FormFieldObj;
  pavementslabs: FormFieldObj;
  sidewalk_length: FormFieldObj;
  sidewalk_width: FormFieldObj;
}
export interface DialogProps {}
const {
  formField: {
    street,
    street_number,
    city,
    postalcode,
    permits,
    backyard,
    lantern,
    bollard,
    watercap,
    remarks,
    frontview,
    sideview,
    sidewalkpavement,
    sidewalk_length,
    sidewalk_width,
  },
} = sidewalkFormModel;

export const sidewalkInitialFormValues = {
  [street.name]: "",
  [street_number.name]: "",
  [city.name]: "",
  [postalcode.name]: "",
  [permits.name]: "",
  [backyard.name]: false,
  [lantern.name]: false,
  [bollard.name]: false,
  [watercap.name]: false,
  [remarks.name]: "",
  [frontview.name]: "",
  [sideview.name]: "",
  [sidewalkpavement.name]: "Pflaster",
  [sidewalk_length.name]: 0,
  [sidewalk_width.name]: 0,
};
