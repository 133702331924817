import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Modal,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  createOffer,
  setOfferStatus,
  setOfferProposalStatus,
} from "../../../services/offerService";
import { ActiveCalucaltionOfferI } from "../../Dashboard/AdminDashboard";
import { Position } from "../../Position/components/CsvImport";
import { PositionTable } from "../components/PositionTable";

interface OfferEditViewProps {
  activeCalucaltionOffer: ActiveCalucaltionOfferI;
  openOfferEditView: boolean;
  setOpenOfferEditView: React.Dispatch<React.SetStateAction<boolean>>;
}

interface OfferDataPosition {
  nr: string;
  einheit: string;
  text: string;
  price: number;
  menge: number;
}
interface OfferData {
  bruttoGesamtPreis: number;
  positions: OfferDataPosition[];
}

/*Displays BruttoGesamtPreis and Positions in View after Calculating the Offer*/
export const OfferEditView = (props: OfferEditViewProps) => {
  const handleClose = () => props.setOpenOfferEditView(false);
  const [offerData, setOfferData] = useState<OfferData>();
  const [positions, setPositions] = useState<Position[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [edit, setEdit] = useState<boolean>(false);
  const style = {
    position: "absolute" as "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const recalcOffer = (): number => {
    if (offerData) {
      const sum = positions.reduce((prev, current) => {
        return prev + current.price * current.menge;
      }, 0);

      return sum;
    }
    return 0;
  };

  const submitOffer = () => {
    setOfferStatus(1, props.activeCalucaltionOffer.costumerId);
    setOfferProposalStatus(1, props.activeCalucaltionOffer.costumerId);
    handleClose();
  };

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const data = await createOffer(
        props.activeCalucaltionOffer.offerProposalId,
        props.activeCalucaltionOffer.costumerId
      );
      if (data && data.data) {
        setPositions(data.data.positions);
        setOfferData({
          bruttoGesamtPreis: data.data.bruttoGesamtPreis,
          positions: data.data.positions,
        });

        setLoading(false);
      }
    };

    if (props.activeCalucaltionOffer.offerProposalId) {
      fetch();
    }
  }, [props.activeCalucaltionOffer.offerProposalId]);
  return (
    <Dialog
      open={props.openOfferEditView}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="md"
    >
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <PositionTable
              positionState={{ positions, setPositions }}
              editState={{ edit, setEdit }}
            />

            {offerData && (
              <Box display="flex" alignItems="end" flexDirection="column">
                <Typography variant="h6" sx={{ mt: 2, mr: 2 }}>
                  Netto Preis:{" "}
                  {(offerData?.bruttoGesamtPreis * 0.81).toFixed(2)} €
                </Typography>
                <Divider />
                <Typography variant="h6" sx={{ mb: 2, mr: 2 }}>
                  Brutto Preis: {recalcOffer().toFixed(2)}€
                </Typography>
                <Box display="flex" gap={2}>
                  <Button
                    color="warning"
                    variant="contained"
                    onClick={() => {
                      setEdit(!edit);
                    }}
                  >
                    {edit ? "Fertig" : "Bearbeiten"}
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submitOffer}
                  >
                    Angebot Erstellen
                  </Button>
                </Box>
              </Box>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
