import { Box, Button, Container } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CsvImport, Position } from "../components/CsvImport";

export const PositionUploadView = () => {
  const [positions, setPositions] = useState<Position[]>();

  return (
    <Container>
      <CsvImport positions={positions} setPositions={setPositions} />
    </Container>
  );
};
