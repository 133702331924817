import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { WorkflowStateI, BooleanFieldI } from "./WorkFlow";

interface BooleanFieldViewProps {
  workflow: WorkflowStateI;
  onHandleClose: () => void;
}
export const BooleanFieldView = (props: BooleanFieldViewProps) => {
  const [booleanField, setBooleanField] = useState<BooleanFieldI>({
    name: "",
    source: "",
    multiplyField: "",
    positions: [],
  });

  const handleChange = (event: SelectChangeEvent) => {
    setBooleanField({ ...booleanField, source: event.target.value });
  };

  const handleMultiplyFieldChange = (event: SelectChangeEvent) => {
    setBooleanField({ ...booleanField, multiplyField: event.target.value });
  };
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBooleanField({ ...booleanField, name: event.target.value });
  };

  const handleOk = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    props.workflow.setWorkflow({
      ...props.workflow.workflow,
      booleanFields: [...props.workflow.workflow.booleanFields, booleanField],
    });

    props.onHandleClose();
  };
  const dummy = ["backyard", "lantern"];

  return (
    <div>
      <TextField fullWidth label="Name" onChange={handleNameChange} />
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="demo-simple-select-label">Source</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={booleanField.source}
          label="Source"
          onChange={handleChange}
        >
          {dummy.map((source, index) => {
            return (
              <MenuItem key={index} value={source}>
                {source}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="demo-simple-select-label">MulitiplyField</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={booleanField.multiplyField}
          label="MultiplyField"
          onChange={handleMultiplyFieldChange}
        >
          {props.workflow.workflow.multipliedFields.map((source, index) => {
            return (
              <MenuItem key={index} value={source.name}>
                {source.name}
              </MenuItem>
            );
          })}

          {props.workflow.workflow.sourceFields.map((source, index) => {
            return (
              <MenuItem key={index} value={source.name}>
                {source.name}
              </MenuItem>
            );
          })}

          {props.workflow.workflow.constantFields.map((source, index) => {
            return (
              <MenuItem key={index} value={source.name}>
                {source.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Button color="secondary" variant="contained" onClick={handleOk}>
        Speichern
      </Button>
    </div>
  );
};
