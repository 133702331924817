// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCJvgaiqsDaiN1LVTMMx4s7RgVLc-2gGQg",
  authDomain: "portly-970a4.firebaseapp.com",
  projectId: "portly-970a4",
  storageBucket: "portly-970a4.appspot.com",
  messagingSenderId: "720483739216",
  appId: "1:720483739216:web:39b150f26e634ee5eb5355",
};

// Initialize Firebase

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
