import {
  collection,
  setDoc,
  doc,
  getDoc,
  FieldValue,
  getDocs,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { auth, db, app } from "../firebaseConfig";
import { v4 as uuidv4 } from "uuid";
import { serverTimestamp } from "firebase/firestore";
import { offerProposalStatusEnum } from "../Client/enums/offerProposalStatusEnum";
import axios from "axios";
import { deployConfig } from "../deployConfig";

export interface ValueProps {
  [x: string]: string | number | boolean;
}

export interface offerProposalDataI {
  street: string;
  street_number: string;
  city: string;
  postalcode: string;
  permits: string;
  backyard: boolean;
  lantern: boolean;
  bollard: boolean;
  watercap: boolean;
  remarks: string;
  frontview: string;
  sideview: string;
  sidewalkpavement: string;
  sidewalk_length: string;
  sidewalk_width: string;
  userId: string;
}

export interface offerProposalFirestoreI {
  userId: string;
  offerProposalData: offerProposalDataI;
  timeStamp: FieldValue;
}
export const saveOfferProposal = async (values: ValueProps) => {
  const user = auth.currentUser;

  if (user) {
    if (user.email) {
      const docRef = await setDoc(doc(db, "offerProposals", user.uid), {
        id: uuidv4(),
        offerProposalData: values,
        timeStamp: serverTimestamp(),
        status: 0,
      });
    }
  }
};

export const getCurrentOfferProposal = async () => {
  const user = auth.currentUser;
  if (user) {
    if (user.email) {
      const docSnap = await getDoc(doc(db, "offerProposals", user.uid));
      if (docSnap.exists()) {
        return docSnap.data();
      }
    }
  }
};

export const getUsername = async (uid: string) => {
  const user = auth.currentUser;
  if (user) {
    if (user.email) {
      const docSnap = await getDoc(doc(db, "users", uid));
      if (docSnap.exists()) {
        return docSnap.data();
      }
    }
  }
};

export const getOfferProposal = async (uid: string) => {
  const user = auth.currentUser;
  if (user) {
    if (user.email) {
      const docSnap = await getDoc(doc(db, "offerProposals", uid));
      if (docSnap.exists()) {
        return docSnap.data();
      }
    }
  }
};

export const getOfferProposals = async () => {
  const user = auth.currentUser;

  if (user) {
    const docSnap = await getDocs(collection(db, "offerProposals"));
    if (docSnap.docs.length > 0) {
      return docSnap.docs;
    }
  }
};

/*Offer has status-Field which represents: create, in progress, active*/
export const createOffer = async (
  offerProposalId: string,
  costumerId: string
) => {
  const user = auth.currentUser;
  if (user) {
    try {
      const data = await axios.post(
        deployConfig.url.API_URL + "/api/createOffer",
        {
          offerProposalId: offerProposalId,
          adminId: user.uid,
          costumerId: costumerId,
        }
      );
      const docRef = await setDoc(doc(db, "offers", costumerId), data.data);
      return data;
    } catch (e) {
      console.log(e);
    }
  }
};

export const getCurrentOffer = async () => {
  const user = auth.currentUser;
  if (user) {
    if (user.email) {
      const docSnap = await getDoc(doc(db, "offers", user.uid));
      if (docSnap.exists()) {
        return docSnap.data();
      }
    }
  }
};

export const deleteCurrentOffer = async () => {
  const user = auth.currentUser;
  if (user) {
    if (user.email) {
      try {
        const docSnap = await deleteDoc(doc(db, "offers", user.uid));
      } catch (e) {
        console.log(e);
      }
    }
  }
};

export const deleteCurrentOfferProposal = async () => {
  const user = auth.currentUser;
  if (user) {
    if (user.email) {
      try {
        const docSnap = await deleteDoc(doc(db, "offerProposals", user.uid));
      } catch (e) {
        console.log(e);
      }
    }
  }
};

export const setOfferStatus = async (status: number, costumerId: string) => {
  try {
    const docRef = doc(db, "offers", costumerId);
    const res = await updateDoc(docRef, { status: status });
  } catch (e) {
    console.log(e);
  }
};

export const setOfferProposalStatus = async (
  status: number,
  costumerId: string
) => {
  try {
    const docRef = doc(db, "offerProposals", costumerId);
    const res = await updateDoc(docRef, { status: status });
  } catch (e) {
    console.log(e);
  }
};

export const fetchFileFromUrl = (url: string) => {};
