export interface LoginFormObj {
  name: string;
  label: string;
  requiredErrorMsg: string;
}

export interface FormField {
  email: LoginFormObj;
  password: LoginFormObj;
}
export const loginFormModel = {
  formField: {
    email: {
      name: "email",
      label: "Email",
      requiredErrorMsg: "Bitte geben Sie eine gültige Email an.",
    },
    password: {
      name: "password",
      label: "Passwort",
      requiredErrorMsg: "Bitte geben Sie ein gültiges Passwort an.",
    },
  },
};
