import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { getOfferProposals } from "../../../services/offerService";
import SearchIcon from "@mui/icons-material/Search";
import EuroIcon from "@mui/icons-material/Euro";
import { ActiveCalucaltionOfferI } from "../../Dashboard/AdminDashboard";
import { useNavigate } from "react-router-dom";

interface OfferProposalsPropsI {
  setActiveOffer: React.Dispatch<
    React.SetStateAction<ActiveCalucaltionOfferI | undefined>
  >;
  setOpenOfferEditView: React.Dispatch<React.SetStateAction<boolean>>;
}
export const OfferProposalDashboardView = (props: OfferProposalsPropsI) => {
  const navigate = useNavigate();
  const checkStatus = (status: number) => {
    return status === 1 ? true : false;
  };
  const [offerProposals, setOfferProposals] = useState<
    QueryDocumentSnapshot<DocumentData>[]
  >();
  useEffect(() => {
    const fetch = async () => {
      const offerProposalsRes = await getOfferProposals();
      if (offerProposalsRes) {
        setOfferProposals(offerProposalsRes);
      }
    };
    fetch();
  }, []);
  return (
    <>
      <Typography variant="h5" gutterBottom sx={{ color: "white" }}>
        Angebotsanfragen
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Kunden Nr..</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Ident.Nr.</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Stadt</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Postleitzahl</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Strasse</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Hausnummer</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Actions</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {offerProposals &&
              offerProposals.length > 0 &&
              offerProposals.map((doc, key) => {
                const offerProposalData = doc.data().offerProposalData;
                const offerProposalId = doc.data().id;
                const status = doc.data().status;

                return (
                  <TableRow key={key}>
                    <TableCell>{doc.id}</TableCell>
                    <TableCell>{offerProposalId}</TableCell>
                    <TableCell>{offerProposalData.city}</TableCell>
                    <TableCell>{offerProposalData.postalcode}</TableCell>
                    <TableCell>{offerProposalData.street}</TableCell>
                    <TableCell>{offerProposalData.street_number}</TableCell>
                    <TableCell>
                      <IconButton color="primary">
                        <SearchIcon
                          onClick={() => {
                            navigate("/offerProposalViewer", {
                              state: { uid: doc.id },
                            });
                          }}
                        />
                      </IconButton>
                      <IconButton
                        disabled={checkStatus(status)}
                        color="success"
                        onClick={() => {
                          props.setActiveOffer({
                            offerProposalId: offerProposalId,
                            costumerId: doc.id,
                          });
                          props.setOpenOfferEditView(true);
                        }}
                      >
                        <EuroIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>{status}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
