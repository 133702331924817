import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext, userContextProps } from "../../App";
import jwt_decode from "jwt-decode";

interface ProtectedRouteProps {
  children: React.ReactNode;
}
export const ProtectedRoute = (props: ProtectedRouteProps) => {
  const email = localStorage.getItem("user");
  const jwt = localStorage.getItem("jwt");

  if (jwt === null || email === undefined) {
    return <Navigate to="/" replace />;
  } else {
    const decodedJwt = jwt_decode(jwt);
    return <div>{props.children}</div>;
  }
};
