import { Slider } from "@mui/material";
import { useField, useFormikContext } from "formik";
import React from "react";

interface MarkI {
  value: number;
  label: string;
}
interface SliderModuleProps {
  name: string;
  defaultValue: number;
  marks: MarkI[];
  step: number;
  meter?: boolean;
  min: number;
  max: number;
}
export const SliderModule = (props: SliderModuleProps) => {
  function centimeter(value: number) {
    return `${value} cm`;
  }
  function meter(value: number) {
    return `${value} m`;
  }

  const [field, meta] = useField(props.name);
  const formikProps = useFormikContext();

  return (
    <>
      <Slider
        marks={props.marks}
        aria-label="Custom marks"
        defaultValue={props.defaultValue}
        step={props.step}
        valueLabelDisplay="auto"
        getAriaValueText={props.meter ? meter : centimeter}
        value={field.value}
        onChange={(e, val) => {
          formikProps.setFieldValue(props.name, val);
        }}
        onChangeCommitted={(_, val) =>
          formikProps.setFieldValue(props.name, val)
        }
        min={props.min}
        max={props.max}
      />
    </>
  );
};
