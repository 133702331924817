import { Stack, Typography } from "@mui/material";
import React from "react";

interface DashboardHeaderProps {
  children: React.ReactNode;
  icon?: any;
}
export const DashboardHeader = (props: DashboardHeaderProps) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={1}
      sx={{
        boxShadow: 5,
        borderRadius: 1,
        mt: 2,
        p: 4,
        backgroundColor: "white",
      }}
    >
      {props.icon && props.icon}
      <Typography variant="h5" component="div" color="primary" sx={{}}>
        {props.children}
      </Typography>
    </Stack>
  );
};
