import { Grid } from "@mui/material";
import React, { useState } from "react";
import { OfferProposalDashboardView } from "../Workflow/views/OfferProposalDashboardView";
import { OfferEditView } from "../Workflow/views/OfferEditView";

export interface ActiveCalucaltionOfferI {
  offerProposalId: string;
  costumerId: string;
}
export const AdminDashboard = () => {
  const [
    activeCalucaltionOffer,
    setActiveCalucaltionOffer,
  ] = useState<ActiveCalucaltionOfferI>();
  const [openOfferEditView, setOpenOfferEditView] = useState<boolean>(false);

  return (
    <>
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12}>
          <OfferProposalDashboardView
            setOpenOfferEditView={setOpenOfferEditView}
            setActiveOffer={setActiveCalucaltionOffer}
          />
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>

      {activeCalucaltionOffer && (
        <OfferEditView
          openOfferEditView={openOfferEditView}
          setOpenOfferEditView={setOpenOfferEditView}
          activeCalucaltionOffer={activeCalucaltionOffer}
        />
      )}
    </>
  );
};
