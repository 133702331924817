import { Box, Card, CardMedia, Typography } from "@mui/material";
import React from "react";
import { Dialog } from "../../components/Dialog";
import { FileUpload } from "../../components/FileUpload";
import { sidewalkFormModel } from "../../../FormikModels/sidewalkFormModel";

export const SideViewDialog = () => {
  const {
    formField: { sideview },
  } = sidewalkFormModel;
  return (
    <Dialog>
      <Typography
        sx={{
          fontSize: {
            lg: 30,
            md: 20,
            sm: 20,
            xs: 20,
          },
        }}
        variant="h6"
      >
        Laden sie ihren Gehweg in der Seitenansicht hoch!
      </Typography>
      <Card sx={{ maxWidth: "400" }}>
        <CardMedia
          component="img"
          image="/gehwegbreite_grafik_evee_heller.jpg"
          alt="Paella dish"
          height="300"
        />
      </Card>
      <Box
        sx={{ mt: 2 }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <FileUpload
          type="image"
          name={sideview.name}
          buttonText="Bild hochladen"
          fileName="sideview"
        />
      </Box>
    </Dialog>
  );
};
