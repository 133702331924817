const prod = {
  url: {
    API_URL: "https://portly-970a4.ew.r.appspot.com",
  },
};
const dev = {
  url: {
    API_URL: "http://localhost:8080",
  },
};

export const deployConfig = process.env.NODE_ENV === "development" ? dev : prod;
