import { Box, Typography } from "@mui/material";
import React from "react";
import { Dialog } from "../../components/Dialog";
import { sL } from "./sidewalkLanguage";
import { sidewalkFormModel } from "../../../FormikModels/sidewalkFormModel";
import { CheckBox } from "../../components/CheckBox";
export const ObstaclesDialog = () => {
  const {
    formField: { lantern, bollard, watercap },
  } = sidewalkFormModel;
  return (
    <Dialog>
      <Typography
        sx={{
          fontSize: {
            lg: 30,
            md: 20,
            sm: 20,
            xs: 20,
          },
        }}
        variant="h6"
      >
        {sL.SIDWALK_OBSTACLES}
      </Typography>
      <Box
        sx={{ mt: 2 }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CheckBox name={lantern.name} label="Laterne" />
        <CheckBox name={bollard.name} label="Pöller" />
        <CheckBox name={watercap.name} label="Wasser/Gaskappe" />
      </Box>
    </Dialog>
  );
};
