import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { auth } from "../../firebaseConfig";

export const ProfileView = () => {
  const user = auth.currentUser;
  const [edit, setEdit] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>();

  const editRender = (
    <Container
      maxWidth="sm"
      sx={{ backgroundColor: "white", mt: 5, borderRadius: 2, p: 2 }}
    >
      <Typography
        sx={{
          fontSize: {
            lg: 30,
            md: 20,
            sm: 20,
            xs: 20,
          },
        }}
        variant="h6"
        color="primary"
      >
        Deine Profil-Informationen
      </Typography>

      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <b>User ID:</b> {user?.uid}
        </Grid>
        <Grid xs={12} item>
          <TextField
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            label="Neue Email"
            error={error ? Boolean(error) : false}
            helperText={error ? error : null}
          />
        </Grid>
      </Grid>

      <Box
        display="flex"
        justifyContent="flex-end"
        alignContent="flex-end"
        sx={{ mt: 2 }}
      >
        <Button
          variant="contained"
          onClick={() => {
            setEdit(false);
          }}
        >
          Speichern
        </Button>
      </Box>
    </Container>
  );
  return edit ? (
    editRender
  ) : (
    <Container
      maxWidth="sm"
      sx={{ backgroundColor: "white", mt: 5, borderRadius: 2, p: 2 }}
    >
      <Typography
        sx={{
          fontSize: {
            lg: 30,
            md: 20,
            sm: 20,
            xs: 20,
          },
        }}
        variant="h6"
        color="primary"
      >
        Deine Profil-Informationen
      </Typography>

      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <b>User ID:</b> {user?.uid}
        </Grid>
        <Grid item>
          <b>Email:</b> {user?.email}
        </Grid>
      </Grid>

      <Box display="flex" justifyContent="flex-end" alignContent="flex-end">
        <Button
          variant="contained"
          onClick={() => {
            setEdit(true);
          }}
        >
          Bearbeiten
        </Button>
      </Box>
    </Container>
  );
};
