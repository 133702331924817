import {
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useField, useFormikContext } from "formik";
import { Dialog } from "./Dialog";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import { UserContext, userContextProps } from "../../App";
import { auth, storage } from "../../firebaseConfig";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
interface FileUploadProps {
  buttonText: string;
  name: string;
  type: string;
  fileName: string;
}
export const FileUpload = (props: FileUploadProps) => {
  const formikProps = useFormikContext();
  const [field, meta] = useField(props.name);
  const [file, setFile] = useState<File | undefined>(undefined);

  const [loading, setLoading] = useState<Boolean>(false);
  const userDocumentsRef = ref(
    storage,
    auth.currentUser?.uid + "/" + props.fileName
  );
  console.log("meta error: ", meta.error);
  const uploadFile = (file: File) => {
    setLoading(true);
    uploadBytes(userDocumentsRef, file)
      .then(async (snapshot) => {
        setLoading(false);
        const downloadUrl = await getDownloadURL(userDocumentsRef);
        formikProps.setFieldValue(props.name, downloadUrl);
      })
      .catch();
  };
  return (
    <Dialog>
      <Typography
        sx={{
          fontSize: {
            lg: 18,
            md: 18,
            sm: 18,
            xs: 18,
          },
          p: 2,
        }}
        variant="h5"
      >
        {meta.error
          ? meta.error
          : field.value !== ""
          ? file
            ? "Hochgeladene Datei: " + file.name
            : "Datei hochgeladen"
          : "Wähle bitte eine Datei aus."}

        {loading ? <CircularProgress color="success" /> : undefined}
        {field.value !== "" && (
          <IconButton>
            <DownloadDoneIcon color="success" />
          </IconButton>
        )}
      </Typography>
      <Button
        variant="contained"
        color={meta.error ? "error" : "success"}
        component="label"
      >
        {props.buttonText}
        <input
          type="file"
          hidden
          onChange={(event) => {
            if (!event.target.files) {
              return;
            }
            const tempFile = event.target.files[0];

            if (props.type === "pdf") {
              if (tempFile.type !== "application/pdf") {
                formikProps.setFieldError(
                  props.name,
                  "Sie haben keine PDF Datei ausgewählt"
                );
              } else {
                setFile(tempFile);
                uploadFile(tempFile);
              }
            } else if (props.type === "image") {
              if (
                tempFile.type !== "image/png" &&
                tempFile.type !== "image/jpeg"
              ) {
                formikProps.setFieldError(
                  props.name,
                  "Sie haben keine Bild-Datei ausgewählt"
                );
              } else {
                setFile(tempFile);
                uploadFile(tempFile);
              }
            }
          }}
        />
      </Button>
    </Dialog>
  );
};
