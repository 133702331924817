import React, { useContext } from "react";
import jwt_decode from "jwt-decode";

import { UserContext, userContextProps } from "../../App";
import { Navigate } from "react-router-dom";
interface AdminRouteProps {
  children: React.ReactNode;
}
export const AdminRoute = (props: AdminRouteProps) => {
  const email = localStorage.getItem("user");
  const jwt = localStorage.getItem("jwt");
  if (jwt === null || email === undefined) {
    return <Navigate to="/" replace />;
  } else {
    const decodedJwt: any = jwt_decode(jwt);
    if (decodedJwt.custom_claims[0] === "ADMIN") {
      return <div>{props.children}</div>;
    } else {
      return <Navigate to="/" replace />;
    }
  }
  return <div>{props.children}</div>;
};
