import {sidewalkFormModel} from "./sidewalkFormModel";
import * as yup from "yup";
const {
  formField: {
    street,
    street_number,
    city,
    postalcode,
    permits,
    backyard,
    lantern,
    bollard,
    watercap,
    frontview,
    sideview,
    sidewalkpavement,
    sidewalk_length,
    sidewalk_width,
  },
} = sidewalkFormModel;

export const sidewalkValidationSchema =  [
  yup.object().shape({
    [street.name]: yup.string().required(street.requiredErrorMsg),
    [street_number.name]: yup.string().required(street_number.requiredErrorMsg),
    [city.name]: yup.string().required(city.requiredErrorMsg),
    [postalcode.name]: yup.string().required(postalcode.requiredErrorMsg),
  }),
  yup.object().shape({
    [permits.name]: yup.string().required(permits.requiredErrorMsg),
  }),
  yup.object().shape({
    [backyard.name]: yup.mixed().notRequired(),
  }),
  yup.object().shape({
    [lantern.name]: yup.mixed().notRequired(),
    [bollard.name]: yup.mixed().notRequired(),
    [watercap.name]: yup.mixed().notRequired(),
  }),
  yup.object().shape({
    [frontview.name]: yup.string().required(),
  }),
  yup.object().shape({
    [sideview.name]: yup.string().required(),
  }),
  yup.object().shape({
    [sidewalk_width.name]: yup.number().required(),
    [sidewalk_length.name]: yup.number().required(),
  }),
  yup.object().shape({
    [sidewalkpavement.name]: yup.string().required(),
  }),
];
