import * as yup from "yup";
import { loginFormModel } from "./loginFormModel";
const {
  formField: { email, password },
} = loginFormModel;

export const loginFormValidationSchema = [
  yup.object().shape({
    [email.name]: yup.string().email().required(email.requiredErrorMsg),
    [password.name]: yup.string().required(password.requiredErrorMsg),
  }),
];
