import { Container, Button, Grid, Typography, Box, Alert } from "@mui/material";
import React, { useContext, useState } from "react";
import { FaFileContract } from "react-icons/fa";
import { signInWithEmailAndPassword } from "firebase/auth";
import InputField from "../../Client/components/InputField";
import { Form, Formik } from "formik";
import { loginInitialValues } from "../../FormikModels/loginInitialValues";
import { loginFormValidationSchema } from "../../FormikModels/loginFormValidationSchema";
import { loginFormModel } from "../../FormikModels/loginFormModel";
import { auth } from "../../firebaseConfig";
import { UserContext, userContextProps, User } from "../../App";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { ErrorI } from "../RegistrationView/RegistrationView";

export const LoginView = () => {
  const {
    formField: { email, password },
  } = loginFormModel;

  const [error, setError] = useState<ErrorI>({ error: false, msg: "" });

  const { setUser } = useContext<userContextProps>(UserContext);
  const navigate = useNavigate();

  return (
    <Container maxWidth="sm" sx={{ mt: 25 }}>
      <Box sx={{ mt: 5, p: 2, backgroundColor: "white", borderRadius: 1 }}>
        <Typography sx={{ mb: 2 }} component="h1" variant="h4" color="primary">
          <FaFileContract /> Login
        </Typography>
        <Formik
          initialValues={loginInitialValues}
          validationSchema={loginFormValidationSchema[0]}
          onSubmit={(values) => {
            signInWithEmailAndPassword(auth, values.email, values.password)
              .then(async (userCredential) => {
                const jwt = await userCredential.user.getIdToken();
                const user = {
                  email: userCredential.user.email,
                  jwt: jwt,
                } as User;
                setUser(user);
                localStorage.setItem("user", userCredential!.user!.email!);
                localStorage.setItem("jwt", jwt);
                navigate("/angebote");
              })
              .catch((error) => {
                if (error) {
                  setError({
                    error: true,
                    msg:
                      "Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.",
                  });
                }
              });
          }}
        >
          {() => (
            <Form>
              <Box>
                <InputField fullWidth name={email.name} label={email.label} />
                <InputField
                  fullWidth
                  name={password.name}
                  label={password.label}
                  type="password"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Jetzt Anmelden
                </Button>
                {error.error && <Alert severity="error">{error.msg}</Alert>}
              </Box>
            </Form>
          )}
        </Formik>

        <Grid container>
          <Grid item xs></Grid>
          <Grid item>
            <Button component={Link} to="/signup">
              Jetzt Registrieren
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
