export interface RegistrationFormObj {
  name: string;
  label: string;
  requiredErrorMsg: string;
}

export interface FormField {
  email: RegistrationFormObj;
  password: RegistrationFormObj;
  fullName: RegistrationFormObj;
}
export const registrationFormModel = {
  formField: {
    email: {
      name: "email",
      label: "Email",
      requiredErrorMsg: "Bitte geben Sie eine gültige Email an.",
    },
    password: {
      name: "password",
      label: "Passwort",
      requiredErrorMsg: "Bitte geben Sie ein gültiges Passwort an.",
    },
    fullName: {
      name: "fullName",
      label: "Vor- und Nachname",
      requiredErrorMsg: "Bitte geben Sie ihren vor und Nachnamen an",
    },
  },
};
