import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";

import { Grid } from "@mui/material";
import { SideWalkLocationDialog } from "./SideWalkLocationDialog";
import { ObstaclesDialog } from "./ObstaclesDialog";
import { sidewalkValidationSchema } from "../../../FormikModels/sidewalkValidationSchema";
import { Form, Formik } from "formik";
import { sidewalkInitialFormValues } from "../../../FormikModels/sidewalkInitialFormValues";
import { PermitDialog } from "./PermitDialog";
import BackyardDialog from "./BackyardDialog";
import { FrontViewDialog } from "./FrontViewDialog";
import { SideViewDialog } from "./SideViewDialog";
import { SideWalkSpecDialog } from "./SideWalkSpecDialog";
import { SidewalkMetrics } from "./SidewalkMetrics";
import { SuccessDialog } from "./SuccessDialog";
import { saveOfferProposal, ValueProps } from "../../../services/offerService";

const steps = [
  "Ort des Bauvorhabens",
  "Gemeinde-Genehmigung",
  "Vorgarten",
  "Hindernisse und Sonstiges",
  "Frontansicht",
  "Längsansicht",
  "Gehweginformationen",
  "Gehwegmaße",
  "Fertig",
];

export interface SidewalkOffer {
  street: string;
  street_number: string;
  city: string;
  postalcode: string;
  permits: string;
  backyard: boolean;
  lantern: boolean;
  bollard: boolean;
  watercap: boolean;
  remarks: string;
  frontview: string;
  sideview: string;
  topview: string;
  sidewalk_length: number;
  sidewalk_width: number;
  flagstone_length: string;
  flagstone_width: string;
  cobblestone?: boolean;
  flagstone?: boolean;
}

export const SideWalkDialogView = () => {
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [desktop, setDesktop] = React.useState<boolean>(false);
  const currentValidationSchema = sidewalkValidationSchema[activeStep];

  function renderStepContent(step: number) {
    switch (step) {
      case 0:
        return <SideWalkLocationDialog />;
      case 1:
        return <PermitDialog />;
      case 2:
        return <BackyardDialog />;
      case 3:
        return <ObstaclesDialog />;
      case 4:
        return <FrontViewDialog />;
      case 5:
        return <SideViewDialog />;
      case 6:
        return <SidewalkMetrics />;
      case 7:
        return <SideWalkSpecDialog />;
    }
  }

  const handleBack = () => {
    if (activeStep > 0) setActiveStep(activeStep - 1);
  };

  const isLastStep = () => {
    if (activeStep === steps.length - 2) return true;
    else return false;
  };

  const handleSubmit = async (values: ValueProps) => {
    saveOfferProposal(values);
  };

  React.useEffect(() => {
    if (window.innerWidth > 1450) {
      setDesktop(true);
    } else {
      setDesktop(false);
    }
    const updateMedia = () => {
      if (window.innerWidth > 1450) {
        setDesktop(true);
      } else {
        setDesktop(false);
      }
    };
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);
  return (
    <Grid
      container
      sx={{
        p: 2,
        justifyContent: "center",
        backgroundColor: "white",
        height: "100vh",
      }}
    >
      <Grid item xs={12} md={2}>
        {desktop ? (
          <Stepper orientation="vertical" activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel color="inherit">{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        ) : (
          `Schritt ${activeStep + 1}/${steps.length}  ${steps[activeStep]} `
        )}
      </Grid>

      <Grid item xs={12} md={10}>
        <React.Fragment>
          {activeStep === steps.length - 1 ? (
            <SuccessDialog downloadAble={false} />
          ) : (
            <Formik
              initialValues={sidewalkInitialFormValues}
              validationSchema={currentValidationSchema}
              onSubmit={(values) => {
                setActiveStep(activeStep + 1);
              }}
            >
              {({ values }) => (
                <Form>
                  {renderStepContent(activeStep)}

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    sx={{
                      my: 2,
                    }}
                  >
                    {activeStep !== 0 && (
                      <Button variant="contained" onClick={handleBack}>
                        Zurück
                      </Button>
                    )}

                    {isLastStep() ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        onClick={() => handleSubmit(values)}
                      >
                        Angebot anfragen
                      </Button>
                    ) : (
                      <Button variant="contained" type="submit">
                        Weiter
                      </Button>
                    )}
                  </Box>
                </Form>
              )}
            </Formik>
          )}
        </React.Fragment>
      </Grid>
    </Grid>
  );
};
